//general
export { default as watchTriangle } from "./general/triangle.svg";
export { default as ctaRightArrow } from "./general/cta-right-arrow.svg";
export { default as navLeftArrow } from "./general/nav-left-arrow.svg";
export { default as navRightArrow } from "./general/nav-right-arrow.svg";
export { default as rightBlackArrow } from "./general/right-black-arrow.png";
export { default as leftBlackArrow } from "./general/left-black-arrow.png";
export { default as quote } from "./general/quote.svg";
export { default as facebook } from "./general/facebook.svg";
export { default as linkedin } from "./general/linkedin.svg";
export { default as twitterX } from "./general/twitterX.svg";
export { default as pinterest } from "./general/pinterest.svg";
export { default as insta } from "./general/insta.svg";
export { default as twitter } from "./general/twitter.svg";
export { default as backto } from "./general/backto.png";
export { default as itimilogo } from "./general/itimilogo.svg";
export { default as mcmlogo } from "./general/mcmlogo.svg";
export { default as testiplayicon } from "./general/testi-play-icon.svg";
export { default as modalcrosswhite } from "./general/modal-cross-white.svg";

export { default as navmcmwhite } from "./general/nav-mcm-white.svg";
export { default as navmcmblack } from "./general/nav-mcm-black.svg";

export { default as navittimi } from "./general/nav-ittimi.svg";

export { default as youtube } from "./general/Youtube_icon_1.png";
export { default as navcallblack } from "./general/nav-call-black.png";
export { default as navcallwhite } from "./general/nav-call-white.png";
export { default as navdownlaodblack } from "./general/nav-downlaod-black.png";
export { default as navdownloadwhite } from "./general/nav-download-white.png";
export { default as navwpblack } from "./general/nav-wp-black.png";
export { default as navwpwhite } from "./general/nav-wp-white.png";
export { default as navimg1 } from "./general/nav-img1.jpg";
export { default as navimg2 } from "./general/nav-img2.png";

export { default as img404 } from "./general/404-img.png";
export { default as circle404 } from "./general/404-circle.png";

export { default as thankyouimg } from "./general/thankyouimg.jpg";
export { default as downArrow } from "./general/down-arrow.png";
export { default as brochurePDF } from "./general/MCM_Brochure_Single_pages.pdf";
export { default as ittimipdf } from "./downloads/ittimi-crafted-interior-cladding.pdf";
export { default as locationicon } from "./general/location.svg";
export { default as loader } from "./general/loader-new.svg";

export { default as Durable } from "./home/Durable.svg";
export { default as Ecological } from "./home/Ecological.svg";
export { default as ExteriorGrade } from "./home/Exterior-Grade.svg";
export { default as Firereistant } from "./home/Fire-reistant.svg";
export { default as Flexible } from "./home/Flexible.svg";
export { default as Shatterproof } from "./home/Shatter_Proof_icon_1.png";
export { default as Slim } from "./home/Slim.svg";

export { default as wood } from "./home/productwood.jpg";
export { default as scroll } from "./home/arrow.gif";

//end of general

export let images = {
  InternalCladding: {
    image: require("./projects/internal_cladding.jpg"),
    alt: "Internal Cladding",
  },
  ExternalCladding: {
    image: require("./projects/external_cladding.jpg"),
    alt: "External Cladding",
  },
  thin: {
    image: require("./home/Thin.png"),
    alt: "Thin",
  },
  ceramic: {
    image: require("./home/ceramic.png"),
    alt: "ceramic",
  },
  easytoinstall: {
    image: require("./home/easy-to-install-easy-application.png"),
    alt: "Easy to instal icon",
  },
  unfired: {
    image: require("./home/Unfired.png"),
    alt: "Unfired icon",
  },
  waterresistant: {
    image: require("./home/Water-Resistant.png"),
    alt: "Water Resistant icon",
  },
  weatherproof: {
    image: require("./home/Weatherproof.png"),
    alt: "Weatherproof icon",
  },
  shatterproof: {
    image: require("./home/Shatterproof.png"),
    alt: "Shatterproof icon",
  },
  surfaceprep: {
    image: require("./home/installimg1.jpg"),
    alt: "surface preparation",
  },
  cutting: {
    image: require("./home/installimg2.jpg"),
    alt: "cutting",
  },
  pasting: {
    image: require("./home/Pasting_3.jpg"),
    alt: "pasting",
  },
  grouting: {
    image: require("./home/Grouting_4.jpg"),
    alt: "grouting",
  },
  cleaning: {
    image: require("./home/installimg5.jpg"),
    alt: "cleaning",
  },
  ceilinghome: {
    image: require("./home/Ceiling-home.jpg"),
    alt: "Ceiling",
  },
  ceilinghomeicon: {
    image: require("./home/Ceiling-home-icon.jpg"),
    alt: "Ceiling",
  },
  ceilinghomeapplication: {
    image: require("./home/Ceiling_home_application.png"),
    alt: "Ceiling",
  },
  ceilingicon: {
    image: require("./home/ceiling-small-new1.png"),
    alt: "Ceiling",
  },
  stonehome: {
    image: require("./home/stone-home.jpg"),
    alt: "Ceiling",
  },
  stonehomeicon: {
    image: require("./home/stone-home-icon.jpg"),
    alt: "Ceiling",
  },
  landscapinghome: {
    image: require("./home/landscaping-home.jpg"),
    alt: "Landscaping",
  },
  landscapinghomeicon: {
    image: require("./home/landscaping-home-icon.jpg"),
    alt: "Landscaping",
  },
  landscapinghome1: {
    image: require("./home/Landscaping_1.jpg"),
    alt: "Landscaping",
  },
  landscapinghomeicon1: {
    image: require("./home/Landscaping_small_box_2.png"),
    alt: "Landscaping",
  },
  furniturecapinghome1: {
    image: require("./home/furniture_covering.png"),
    alt: "Landscaping",
  },
  furniturecapinghomeicon1: {
    image: require("./home/Furniture_Covering_small_box_1.png"),
    alt: "Landscaping",
  },
  flooringhome1: {
    image: require("./home/flooring-home-1.png"),
    alt: "Flooring",
  },
  flooringhomeicon: {
    image: require("./home/flooring-home-icon.jpg"),
    alt: "Flooring",
  },
  external1: {
    image: require("./projects/external1.jpg"),
    alt: "External 1",
  },
  external2: {
    image: require("./projects/external2.jpg"),
    alt: "External 2",
  },
  external3: {
    image: require("./projects/external3.jpg"),
    alt: "External 3",
  },
  external4: {
    image: require("./projects/project-cat1.png"),
    alt: "External 4",
  },
  external5: {
    image: require("./projects/projectimg1.png"),
    alt: "External 5",
  },
  external6: {
    image: require("./projects/projectimg2.png"),
    alt: "External 6",
  },
  external7: {
    image: require("./projects/projectimg3.png"),
    alt: "External 7",
  },
  internal1: {
    image: require("./projects/project-cat2.png"),
    alt: "Internal 1",
  },
  aboutusimg: {
    image: require("./products/productimg.png"),
    alt: "about us image",
  },
  // brick1: {
  //   image: require("./products/brick/A_Series_Facing_Bricks_054058.jpg"),
  //   alt: "a series facing bricks 054058",
  // },
  brick1: {
    image: require("./products/brick/A_Series_Brick_054058_2.jpg"),
    alt: "A Series Facing Bricks 054058",
  },
  brick2: {
    image: require("./products/brick/A_Series_Brick_087089_2.jpg"),
    alt: "a series facing bricks 087089",
  },
  brick3: {
    image: require("./products/brick/A_Series_Brick_308317_2.jpg"),
    alt: "a series facing bricks 308317",
  },
  brick4: {
    image: require("./products/brick/A_Series_Brick_221228231_2.jpg"),
    alt: "a series facing bricks 221228231",
  },
  brick5: {
    image: require("./products/brick/A_Series_Facing_Bricks_052_2.jpg"),
    alt: "A Series Facing Bricks 052",
  },
  brick6: {
    image: require("./products/brick/A_Series_Facing_Bricks_090C8_2.jpg"),
    alt: "A Series Facing Bricks 090C8",
  },
  brick7: {
    image: require("./products/brick/G_Series_Facing_Bricks_031043_2.jpg"),
    alt: "g series facing bricks 031043",
  },
  brick8: {
    image: require("./products/brick/K_Series_Facing_Bricks_052_3.jpg"),
    alt: "k series facing bricks 052",
  },
  brick9: {
    image: require("./products/brick/K_Series_Facing_Bricks_087C4_2.jpg"),
    alt: "k series facing bricks 087C4",
  },
  brick10: {
    image: require("./products/brick/K_Series_Facing_Bricks_090308_2.jpg"),
    alt: "k series facing bricks 090308",
  },
  brick11: {
    image: require("./products/brick/K_Series_Facing_Bricks_326C4_2.jpg"),
    alt: "k series facing bricks 326C4",
  },
  // brick11: {
  //   image: require("./products/brick/K_Series_Facing_Bricks_228030.jpg"),
  //   alt: "k series facing bricks 228030",
  // },
  brick12: {
    image: require("./products/brick/R_Series_Facing_Bricks_087089_2.jpg"),
    alt: "r series facing bricks 087089",
  },
  brick13: {
    image: require("./products/brick/R_Series_Facing_Bricks_308317_2.jpg"),
    alt: "r series facing bricks 308317",
  },

  stone1: {
    image: require("./products/stone/Slate_052_2.jpg"),
    alt: "Slate 052",
  },
  stone2: {
    image: require("./products/stone/Slate_Art_2.jpg"),
    alt: "Slate Arte",
  },
  stone3: {
    image: require("./products/stone/Slate_Phantom_2.jpg"),
    alt: "Slate Phantom",
  },
  stone4: {
    image: require("./products/stone/Slate_Sunnye_2.jpg"),
    alt: "Slate Sunnye",
  },
  stone5: {
    image: require("./products/stone/Slate_Volcanoe_2.jpg"),
    alt: "Slate Volcanoe",
  },
  stone6: {
    image: require("./products/stone/Slate_043_2.jpg"),
    alt: "Slate 043",
  },
  stone7: {
    image: require("./products/stone/Oasis_Foge_2.jpg"),
    alt: "Oasis Foge",
  },
  stone8: {
    image: require("./products/stone/Oasis_Raine_2.jpg"),
    alt: "Oasis Raine",
  },
  stone9: {
    image: require("./products/stone/Oasis_025_2.jpg"),
    alt: "Oasis Stone 025",
  },
  stone10: {
    image: require("./products/stone/Oasis_043_2.jpg"),
    alt: "Oasis Stone 043",
  },
  stone11: {
    image: require("./products/stone/Oasis_221_2.jpg"),
    alt: "Oasis Stone 221",
  },
  stone12: {
    image: require("./products/stone/Oasis_Winde_2.jpg"),
    alt: "Oasis Winde",
  },
  stone13: {
    image: require("./products/stone/Travertine_031YZD_2.jpg"),
    alt: "Travertine 031YZD",
  },
  stone14: {
    image: require("./products/stone/Travertine_052KZ_2.jpg"),
    alt: "Travertine 052KZ",
  },
  stone15: {
    image: require("./products/stone/Travertine_052053_2.jpg"),
    alt: "Travertine 052053",
  },
  stone16: {
    image: require("./products/stone/Travertine_Andes_Gold_2.jpg"),
    alt: "Travertine Andes Gold",
  },
  stone17: {
    image: require("./products/stone/Travertine_2Andes_Grey_2.jpg"),
    alt: "Travertine Andes Grey",
  },
  stone18: {
    image: require("./products/stone/Travertine_Andes_Red_2.jpg"),
    alt: "Travertine Andes Red",
  },
  stone19: {
    image: require("./products/stone/Travertine_Andes_Yellow_2.jpg"),
    alt: "Travertine Andes Yellow",
  },
  // stone20: {
  //   image: require("./products/stone/Sandstone 052.jpg"),
  //   alt: "Sandstone 052",
  // },
  stone20: {
    image: require("./products/stone/Sandstone_Cloude_2.jpg"),
    alt: "Sandstone Cloude",
  },
  stone21: {
    image: require("./products/stone/Sandstone_Sunshine_2.jpg"),
    alt: "Sandstone Sunshine",
  },
  stone22: {
    image: require("./products/stone/Sandstone_Vision_2.jpg"),
    alt: "Sandstone Vision",
  },
  stone23: {
    image: require("./products/stone/Sandstone_Australia_Orange_2.jpg"),
    alt: "Sandstone Australia Orange",
  },
  stone24: {
    image: require("./products/stone/Cut_Stone_052_2.jpg"),
    alt: "Cutstone 052",
  },
  stone25: {
    image: require("./products/stone/Cut_Stone_221_2.jpg"),
    alt: "Cutstone 221",
  },
  stone26: {
    image: require("./products/stone/Cut_Stone_362_2.jpg"),
    alt: "Cutstone 362",
  },
  // stone27: {
  //   image: require("./products/stone/Cutstone 369.jpg"),
  //   alt: "Cutstone 369",
  // },
  stone27: {
    image: require("./products/stone/Granite_052_2.jpg"),
    alt: "Granite 052",
  },
  stone28: {
    image: require("./products/stone/Granite_209_2.jpg"),
    alt: "Granite 209",
  },
  stone29: {
    image: require("./products/stone/Granite_308_2.jpg"),
    alt: "Granite 308",
  },
  stone30: {
    image: require("./products/stone/Granite_317_2.jpg"),
    alt: "Granite 317",
  },
  stone31: {
    image: require("./products/stone/Infiniti_031_2.jpg"),
    alt: "Infiniti 031",
  },
  stone32: {
    image: require("./products/stone/Infiniti_043_2.jpg"),
    alt: "Infiniti 043",
  },
  stone33: {
    image: require("./products/stone/Infiniti_052_2.jpg"),
    alt: "Infiniti 052",
  },
  stone34: {
    image: require("./products/stone/Infiniti_058_2.jpg"),
    alt: "Infiniti 058",
  },
  stone35: {
    image: require("./products/stone/35_Piece_Stack_Stone_058_2.jpg"),
    alt: "35 Piece Stack Stone - 058",
  },
  stone36: {
    image: require("./products/stone/35_Piece_Stack_Stone_206_2.jpg"),
    alt: "35 Piece Stack Stone - 206",
  },
  stone37: {
    image: require("./products/stone/35_Piece_Stack_Stone_315_2.jpg"),
    alt: "35 Piece Stack Stone - 315",
  },
  stone38: {
    image: require("./products/stone/MCM_Golden_Lolite_2.jpg"),
    alt: "Golden Lolite",
  },
  stone39: {
    image: require("./products/stone/MCM_Ink_Painting_Stone_2.jpg"),
    alt: "Ink Painting Stone",
  },
  stone40: {
    image: require("./products/stone/Crossard_Mushroom_Stone_221_2.jpg"),
    alt: "Crossard Mushroom Stone 221",
  },
  stone41: {
    image: require("./products/stone/Crossard_Mushroom_Stone_052_2.jpg"),
    alt: "Crossard Mushroom Stone 052",
  },
  stone42: {
    image: require("./products/stone/Crossard_Mushroom_Stone_043_2.jpg"),
    alt: "Crossard Mushroom Stone 043",
  },
  stone43: {
    image: require("./products/stone/Devine_Mushroom_052_2.jpg"),
    alt: "Devine Mushroom Stone 052",
  },
  stone44: {
    image: require("./products/stone/Devine_Mushroom_043_2.jpg"),
    alt: "Devine Mushroom Stone 043",
  },
  stone45: {
    image: require("./products/stone/Devine_Mushroom_031_2.jpg"),
    alt: "Devine Mushroom Stone 031",
  },
  stone46: {
    image: require("./products/stone/Limestone_Nile_Dark_Grey_2.jpg"),
    alt: "Limestone Nile Dark Grey",
  },
  stone47: {
    image: require("./products/stone/Limestone_Nile_Cool_Grey_2.jpg"),
    alt: "Limestone Nile Cool Grey",
  },
  stone48: {
    image: require("./products/stone/Marble_Veil_Grey_2.jpg"),
    alt: "Marble Veil Grey",
  },
  stone49: {
    image: require("./products/stone/Polished_Concrete_Medium_Grey_2.jpg"),
    alt: "Polished Concrete Medium Grey",
  },
  stone50: {
    image: require("./products/stone/Polish_Concrete_Wall_Dark_Brown_2.jpg"),
    alt: "Polish Concrete Wall Dark Brown",
  },
  stone51: {
    image: require("./products/stone/Rammed_Earth_Wall_Red_2.jpg"),
    alt: "Rammed Earth Wall Red",
  },
  stone52: {
    image: require("./products/stone/Rammed_Earth_Wall_Medium_Grey_2.jpg"),
    alt: "Rammed Earth Wall Medium Grey",
  },
  stone53: {
    image: require("./products/stone/Rammed_Earth_Wall_Light_Grey_2.jpg"),
    alt: "Rammed Earth Wall Light Grey",
  },
  // stone54: {
  //   image: require("./products/stone/MCM_Watermark.jpg"),
  //   alt: "MCM Watermark",
  // },
  // stone4: {
  //   image: require("./products/stone/Concerto 052.jpg"),
  //   alt: "Concerto 052",
  // },
  // stone27: {
  //   image: require("./products/stone/Rockface 043.jpg"),
  //   alt: "Rockface 043",
  // },
  // stone28: {
  //   image: require("./products/stone/Rockface 052.jpg"),
  //   alt: "Rockface 052",
  // },

  wood1: {
    image: require("./products/wood/Jagged_Wood_Olympus_2.jpg"),
    alt: "Jagged Wood Olympus",
  },
  wood2: {
    image: require("./products/wood/Original_Wood_Rhine_2.jpg"),
    alt: "Original Wood Rhine",
  },
  wood3: {
    image: require("./products/wood/Original_Wood_Suez_2.jpg"),
    alt: "Original Wood Suez",
  },
  wood4: {
    image: require("./products/wood/Original_Wood_Volga_2.jpg"),
    alt: "Original Wood Volga",
  },
  piecestackstonesite1thumb: {
    image: require("./projects/Internal/35-piece-stack-stone-site-1/35-piece-stack-stone-site-1-thumb.jpg"),
    alt: "35 piece stack stone thumb",
  },
  piecestackstonesite1one: {
    image: require("./projects/Internal/35-piece-stack-stone-site-1/35-piece-stack-stone-site-1.JPG"),
    alt: "35 piece stack stone",
  },
  brickssitethumb: {
    image: require("./projects/Internal/bricks-a308317-site-1/bricks-a308317-site-1-thumb.jpg"),
    alt: "Bricks A308317 thumb",
  },
  brickssiteone: {
    image: require("./projects/Internal/bricks-a308317-site-1/bricks-a308317-site-1.jpg"),
    alt: "Bricks A308317",
  },
  bricksk052thumb: {
    image: require("./projects/Internal/bricks-k052-centrum-park-mumbai/bricks-k052-site-1-thumb.jpg"),
    alt: "Bricks K052 thumb",
  },
  bricksk052one: {
    image: require("./projects/Internal/bricks-k052-centrum-park-mumbai/bricks-k052-site-1.jpg"),
    alt: "Bricks K052",
  },
  bricksk052two: {
    image: require("./projects/Internal/bricks-k052-centrum-park-mumbai/bricks-k052-site-2.jpg"),
    alt: "Bricks K052",
  },
  bricksk052three: {
    image: require("./projects/Internal/bricks-k052-centrum-park-mumbai/bricks-k052-site-3.jpg"),
    alt: "Bricks K052",
  },
  bricksk326c4thumb: {
    image: require("./projects/Internal/bricks-k326c4/bricks-k326c4-site-1-thumb.jpg"),
    alt: "Bricks K326c4",
  },
  bricksk326c4one: {
    image: require("./projects/Internal/bricks-k326c4/bricks-k326c4-site-1.jpg"),
    alt: "Bricks K326c4",
  },
  bricksk326c4two: {
    image: require("./projects/Internal/bricks-k326c4/bricks-k326c4-site-2.jpg"),
    alt: "Bricks K326c4",
  },
  bricksk228030thumb: {
    image: require("./projects/Internal/bricks-k228030-transcon-developers-mumbai/bricks-k228030-site-1-thumb.jpg"),
    alt: "Bricks k228030",
  },
  bricksk228030one: {
    image: require("./projects/Internal/bricks-k228030-transcon-developers-mumbai/bricks-k228030-site-1.jpg"),
    alt: "Bricks k228030",
  },
  bricksk228030two: {
    image: require("./projects/Internal/bricks-k228030-transcon-developers-mumbai/bricks-k228030-site-2.jpg"),
    alt: "Bricks k228030",
  },
  bricksk228030three: {
    image: require("./projects/Internal/bricks-k228030-transcon-developers-mumbai/bricks-k228030-site-3.jpg"),
    alt: "Bricks k228030",
  },
  bricksk228030four: {
    image: require("./projects/Internal/bricks-k228030-transcon-developers-mumbai/bricks-k228030-site-4.jpg"),
    alt: "Bricks k228030",
  },
  bricksr087089thumb: {
    image: require("./projects/Internal/bricks-r-087089-commercial-space-indonesia/bricks-r087089-site-1-thumb.jpg"),
    alt: "Bricks r087089",
  },
  bricksr087089one: {
    image: require("./projects/Internal/bricks-r-087089-commercial-space-indonesia/bricks-r087089-site-1.jpg"),
    alt: "Bricks r087089",
  },
  bricksr087089two: {
    image: require("./projects/Internal/bricks-r-087089-commercial-space-indonesia/bricks-r087089-site-2.jpg"),
    alt: "Bricks r087089",
  },
  bricksr087089three: {
    image: require("./projects/Internal/bricks-r-087089-commercial-space-indonesia/bricks-r087089-site-3.jpg"),
    alt: "Bricks r087089",
  },
  bricksr087089four: {
    image: require("./projects/Internal/bricks-r-087089-commercial-space-indonesia/bricks-r087089-site-4.jpg"),
    alt: "Bricks r087089",
  },
  bricksr087089five: {
    image: require("./projects/Internal/bricks-r-087089-commercial-space-indonesia/bricks-r087089-site-5.jpg"),
    alt: "Bricks r087089",
  },
  bricksr087089six: {
    image: require("./projects/Internal/bricks-r-087089-commercial-space-indonesia/bricks-r087089-site-6.jpg"),
    alt: "Bricks r087089",
  },
  bricksa221228231thumb: {
    image: require("./projects/Internal/bricks-a221228231-abhmanshree-society/bricks-a221228231-site-1-thumb.jpg"),
    alt: "Bricks a221228231",
  },
  bricksa221228231one: {
    image: require("./projects/Internal/bricks-a221228231-abhmanshree-society/bricks-a221228231-site-1.jpg"),
    alt: "Bricks a221228231",
  },
  bricksa221228231two: {
    image: require("./projects/Internal/bricks-a221228231-abhmanshree-society/bricks-a221228231-site-2.jpg"),
    alt: "Bricks a221228231",
  },
  concertostonethumb: {
    image: require("./projects/Internal/concerto-052-jolly-banerjee-residence/concerto-stone-1-thumb.jpg"),
    alt: "concerto-stone",
  },
  concertostoneone: {
    image: require("./projects/Internal/concerto-052-jolly-banerjee-residence/concerto-stone-1.jpg"),
    alt: "concerto-stone",
  },
  concertostonetwo: {
    image: require("./projects/Internal/concerto-052-jolly-banerjee-residence/concerto-stone-2.jpg"),
    alt: "concerto-stone",
  },
  concertowetthumb: {
    image: require("./projects/Internal/concreto-internal-cladding-wet-area/concerto-stone-1-thumb.jpg"),
    alt: "concerto-stone",
  },
  concertowetone: {
    image: require("./projects/Internal/concreto-internal-cladding-wet-area/concerto-stone-1.jpg"),
    alt: "concerto-stone",
  },
  concertowettwo: {
    image: require("./projects/Internal/concreto-internal-cladding-wet-area/concerto-stone-2.jpg"),
    alt: "concerto-stone",
  },
  concertowetthree: {
    image: require("./projects/Internal/concreto-internal-cladding-wet-area/concerto-stone-3.jpg"),
    alt: "concerto-stone",
  },
  concertowetfour: {
    image: require("./projects/Internal/concreto-internal-cladding-wet-area/concerto-stone-4.jpg"),
    alt: "concerto-stone",
  },
  crocothumb: {
    image: require("./projects/Internal/croco/croco-leather-bathroom-cladding-thumb.jpg"),
    alt: "croco-leather-bathroom",
  },
  crocoone: {
    image: require("./projects/Internal/croco/croco-leather-bathroom-1.jpg"),
    alt: "croco-leather-bathroom",
  },
  crocotwo: {
    image: require("./projects/Internal/croco/croco-leather-bathroom-2.jpg"),
    alt: "croco-leather-bathroom",
  },
  crocothree: {
    image: require("./projects/Internal/croco/croco-leather-bathroom-3.jpg"),
    alt: "croco-leather-bathroom",
  },
  crocoleatherthumb: {
    image: require("./projects/Internal/croco-office-internal-wall-cladding/croco-leather-thumb.jpg"),
    alt: "croco-leather",
  },
  crocoleatherone: {
    image: require("./projects/Internal/croco-office-internal-wall-cladding/croco-leather-1.jpg"),
    alt: "croco-leather",
  },
  crocoleathertwo: {
    image: require("./projects/Internal/croco-office-internal-wall-cladding/croco-leather-2.jpg"),
    alt: "croco-leather",
  },
  crocoleatherthree: {
    image: require("./projects/Internal/croco-office-internal-wall-cladding/croco-leather-3.jpg"),
    alt: "croco-leather",
  },
  crossardmushroomthumb: {
    image: require("./projects/Internal/crossard-mushroom/crossard-mushroom-stone-thumb.jpg"),
    alt: "croco-leather",
  },
  crossardmushroomone: {
    image: require("./projects/Internal/crossard-mushroom/crossard-mushroom-stone-1.jpg"),
    alt: "croco-leather",
  },
  crossardmushroomtwo: {
    image: require("./projects/Internal/crossard-mushroom/crossard-mushroom-stone-2.jpg"),
    alt: "croco-leather",
  },
  crossardmushroomthree: {
    image: require("./projects/Internal/crossard-mushroom/crossard-mushroom-stone-3.jpg"),
    alt: "croco-leather",
  },
  crossardmushroomfour: {
    image: require("./projects/Internal/crossard-mushroom/crossard-mushroom-stone-4.jpg"),
    alt: "croco-leather",
  },
  crossardmushroomfive: {
    image: require("./projects/Internal/crossard-mushroom/crossard-mushroom-stone-5.jpg"),
    alt: "croco-leather",
  },
  cutstonespacethumb: {
    image: require("./projects/Internal/cutstone-commercial-space/cut-stone-thumb.jpg"),
    alt: "croco-leather",
  },
  cutstonespaceone: {
    image: require("./projects/Internal/cutstone-commercial-space/cut-stone-1.jpg"),
    alt: "croco-leather",
  },
  cutstonespacetwo: {
    image: require("./projects/Internal/cutstone-commercial-space/cut-stone-2.jpg"),
    alt: "croco-leather",
  },
  cutstonespacethree: {
    image: require("./projects/Internal/cutstone-commercial-space/cut-stone-3.jpg"),
    alt: "croco-leather",
  },
  cutstonespacefour: {
    image: require("./projects/Internal/cutstone-commercial-space/cut-stone-4.jpg"),
    alt: "croco-leather",
  },
  devinemushroom052thumb: {
    image: require("./projects/Internal/devine-mushroom/devine-mushroom-stone-052-thumb.jpg"),
    alt: "devine mushroom",
  },
  devinemushroom052one: {
    image: require("./projects/Internal/devine-mushroom/devine-mushroom-stone-1.jpg"),
    alt: "devine mushroom",
  },
  devinemushroom052two: {
    image: require("./projects/Internal/devine-mushroom/devine-mushroom-stone-2.jpg"),
    alt: "devine mushroom",
  },
  devinemushroom052three: {
    image: require("./projects/Internal/devine-mushroom/devine-mushroom-stone-3.jpg"),
    alt: "devine mushroom",
  },
  foodmallthumb: {
    image: require("./projects/Internal/foodmall-lonavala/bricks-a087089-site-thumb.jpg"),
    alt: "foodmall",
  },
  foodmallone: {
    image: require("./projects/Internal/foodmall-lonavala/bricks-a087089-site-1.JPG"),
    alt: "foodmall",
  },
  foodmalltwo: {
    image: require("./projects/Internal/foodmall-lonavala/bricks-a087089-site-2.JPG"),
    alt: "foodmall",
  },
  foodmallthree: {
    image: require("./projects/Internal/foodmall-lonavala/bricks-a087089-site-3.JPG"),
    alt: "foodmall",
  },
  foodmallfour: {
    image: require("./projects/Internal/foodmall-lonavala/bricks-a087089-site-4.JPG"),
    alt: "foodmall",
  },
  foodmallfive: {
    image: require("./projects/Internal/foodmall-lonavala/bricks-a087089-site-5.JPG"),
    alt: "foodmall",
  },
  goldenlolitethumb: {
    image: require("./projects/Internal/golden-lolite/golden-lolite-stone-thumb.jpg"),
    alt: "golden lolite",
  },
  goldenloliteone: {
    image: require("./projects/Internal/golden-lolite/golden-lolite-stone-1.jpg"),
    alt: "golden lolite",
  },
  goldenlolitetwo: {
    image: require("./projects/Internal/golden-lolite/golden-lolite-stone-2.jpg"),
    alt: "golden lolite",
  },
  goldenlolitethree: {
    image: require("./projects/Internal/golden-lolite/golden-lolite-stone-3.jpg"),
    alt: "golden lolite",
  },
  goldenlolitefour: {
    image: require("./projects/Internal/golden-lolite/golden-lolite-stone-4.jpg"),
    alt: "golden lolite",
  },
  granite043thumb: {
    image: require("./projects/Internal/granite-restaurant/granite-043-thumb.jpg"),
    alt: "granite 043",
  },
  granite043one: {
    image: require("./projects/Internal/granite-restaurant/granite-043-1.jpg"),
    alt: "granite 043",
  },
  granite043two: {
    image: require("./projects/Internal/granite-restaurant/granite-043-2.jpg"),
    alt: "granite 043",
  },
  granite043three: {
    image: require("./projects/Internal/granite-restaurant/granite-043-3.jpg"),
    alt: "granite 043",
  },
  granite052thumb: {
    image: require("./projects/Internal/granite-052-private-residence/granite-052-thumb.jpg"),
    alt: "granite 052",
  },
  granite052one: {
    image: require("./projects/Internal/granite-052-private-residence/granite-052-1.jpg"),
    alt: "granite 052",
  },
  granite052two: {
    image: require("./projects/Internal/granite-052-private-residence/granite-052-2.jpg"),
    alt: "granite 052",
  },
  granite052indonesiacafethumb: {
    image: require("./projects/Internal/granite-052-indonesia-cafe/granite-052-indonesia-cafe-thumb.jpg"),
    alt: "granite 052 indonesia cafe",
  },
  granite052indonesiacafeone: {
    image: require("./projects/Internal/granite-052-indonesia-cafe/granite-052-indonesia-cafe-1.jpg"),
    alt: "granite 052 indonesia cafe",
  },
  granite052indonesiacafetwo: {
    image: require("./projects/Internal/granite-052-indonesia-cafe/granite-052-indonesia-cafe-2.jpg"),
    alt: "granite 052 indonesia cafe",
  },
  infinitistonethumb: {
    image: require("./projects/Internal/infiniti-stone-bombay-cocktail-bar-mumbai/infiniti-stone-thumb.jpg"),
    alt: "infiniti stone",
  },
  infinitistoneone: {
    image: require("./projects/Internal/infiniti-stone-bombay-cocktail-bar-mumbai/infiniti-stone-1.jpg"),
    alt: "infiniti stone",
  },
  infinitistonetwo: {
    image: require("./projects/Internal/infiniti-stone-bombay-cocktail-bar-mumbai/infiniti-stone-2.jpg"),
    alt: "infiniti stone",
  },
  infinitistonethree: {
    image: require("./projects/Internal/infiniti-stone-bombay-cocktail-bar-mumbai/infiniti-stone-3.jpg"),
    alt: "infiniti stone",
  },
  infinitistonefour: {
    image: require("./projects/Internal/infiniti-stone-bombay-cocktail-bar-mumbai/infiniti-stone-4.jpg"),
    alt: "infiniti stone",
  },
  infinitistonefive: {
    image: require("./projects/Internal/infiniti-stone-bombay-cocktail-bar-mumbai/infiniti-stone-5.jpg"),
    alt: "infiniti stone",
  },
  inkpaintingthumb: {
    image: require("./projects/Internal/ink-painting/ink-painting-stone-mumbai-site-thumb.jpg"),
    alt: "ink painting stone",
  },
  inkpaintingone: {
    image: require("./projects/Internal/ink-painting/ink-painting-stone-mumbai-site-1.jpg"),
    alt: "ink painting stone",
  },
  inkpaintingtwo: {
    image: require("./projects/Internal/ink-painting/ink-painting-stone-mumbai-site-2.jpg"),
    alt: "ink painting stone",
  },
  inkpaintingthree: {
    image: require("./projects/Internal/ink-painting/ink-painting-stone-mumbai-site-3.jpg"),
    alt: "ink painting stone",
  },
  jaggedwoodthumb: {
    image: require("./projects/Internal/jagged-wood-olympus-ceiling-cladding-bengaluru/jagged-wood-olympus-ceiling-cladding-bengaluru-thumb.jpg"),
    alt: "jagged wood olympus ceiling cladding",
  },
  jaggedwoodone: {
    image: require("./projects/Internal/jagged-wood-olympus-ceiling-cladding-bengaluru/jagged-wood-olympus-ceiling-cladding-bengaluru-1.jpg"),
    alt: "jagged wood olympus ceiling cladding",
  },
  jaggedwoodtwo: {
    image: require("./projects/Internal/jagged-wood-olympus-ceiling-cladding-bengaluru/jagged-wood-olympus-ceiling-cladding-bengaluru-2.jpg"),
    alt: "jagged wood olympus ceiling cladding",
  },
  jaggedwoodthree: {
    image: require("./projects/Internal/jagged-wood-olympus-ceiling-cladding-bengaluru/jagged-wood-olympus-ceiling-cladding-bengaluru-3.jpg"),
    alt: "jagged wood olympus ceiling cladding",
  },
  jaggedwoodfour: {
    image: require("./projects/Internal/jagged-wood-olympus-ceiling-cladding-bengaluru/jagged-wood-olympus-ceiling-cladding-bengaluru-4.jpg"),
    alt: "jagged wood olympus ceiling cladding",
  },
  jaggedwoodfive: {
    image: require("./projects/Internal/jagged-wood-olympus-ceiling-cladding-bengaluru/jagged-wood-olympus-ceiling-cladding-bengaluru-5.jpg"),
    alt: "jagged wood olympus ceiling cladding",
  },
  jwmariottthumb: {
    image: require("./projects/Internal/jw-mariott-nashik-bricks-a087089/jw-mariott-nashik-bricks-a087089-thumb.jpg"),
    alt: "jw mariott nashik bricks a087089",
  },
  jwmariottone: {
    image: require("./projects/Internal/jw-mariott-nashik-bricks-a087089/jw-mariott-nashik-bricks-a087089-1.jpg"),
    alt: "jw mariott nashik bricks a087089",
  },
  jwmariotttwo: {
    image: require("./projects/Internal/jw-mariott-nashik-bricks-a087089/jw-mariott-nashik-bricks-a087089-2.jpg"),
    alt: "jw mariott nashik bricks a087089",
  },
  oasisstone043thumb: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-thumb.jpg"),
    alt: "oasis stone 043",
  },
  oasisstone043one: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-1.jpg"),
    alt: "oasis stone 043",
  },
  oasisstone043two: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-2.jpg"),
    alt: "oasis stone 043",
  },
  oasisstone043three: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-3.jpg"),
    alt: "oasis stone 043",
  },
  oasisstone043four: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-4.jpg"),
    alt: "oasis stone 043",
  },
  oasisstone043five: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-5.jpg"),
    alt: "oasis stone 043",
  },
  oasisstone043six: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-6.jpg"),
    alt: "oasis stone 043",
  },
  oasisstone043seven: {
    image: require("./projects/Internal/oasis-stone-private-residence-pune/oasis-stone-043-7.jpg"),
    alt: "oasis stone 043",
  },
  rockface043thumb: {
    image: require("./projects/Internal/rockface-stone-043-private-villa/rockface-043-thumb.jpg"),
    alt: "rockface 043",
  },
  rockface043one: {
    image: require("./projects/Internal/rockface-stone-043-private-villa/rockface-043-1.jpg"),
    alt: "rockface 043",
  },
  rockface043two: {
    image: require("./projects/Internal/rockface-stone-043-private-villa/rockface-043-2.jpg"),
    alt: "rockface 043",
  },
  rockface043three: {
    image: require("./projects/Internal/rockface-stone-043-private-villa/rockface-043-3.jpg"),
    alt: "rockface 043",
  },
  rockfacestonethumb: {
    image: require("./projects/Internal/rockface-lounge/rockface-stone-thumb.jpg"),
    alt: "rockface 043",
  },
  rockfacestoneone: {
    image: require("./projects/Internal/rockface-lounge/rockface-stone-1.jpg"),
    alt: "rockface 043",
  },
  rockfacestonetwo: {
    image: require("./projects/Internal/rockface-lounge/rockface-stone-2.jpg"),
    alt: "rockface 043",
  },
  rockfacestonethree: {
    image: require("./projects/Internal/rockface-lounge/rockface-stone-3.jpg"),
    alt: "rockface 043",
  },
  rockface052thumb: {
    image: require("./projects/Internal/rockface-residential/rockface-052-thumb.jpg"),
    alt: "rockface 052",
  },
  rockface052one: {
    image: require("./projects/Internal/rockface-residential/rockface-052-1.jpg"),
    alt: "rockface 052",
  },
  rockface052two: {
    image: require("./projects/Internal/rockface-residential/rockface-052-2.jpg"),
    alt: "rockface 052",
  },
  sandstoneoasisstonethumb: {
    image: require("./projects/Internal/sandstone-oasis-stone-chennai-metro-ltd/sandstone-oasis-stone-thumb.jpg"),
    alt: "sandstone oasis stone",
  },
  sandstoneoasisstoneone: {
    image: require("./projects/Internal/sandstone-oasis-stone-chennai-metro-ltd/sandstone-oasis-stone-1.jpg"),
    alt: "sandstone oasis stone",
  },
  sandstoneoasisstonetwo: {
    image: require("./projects/Internal/sandstone-oasis-stone-chennai-metro-ltd/sandstone-oasis-stone-2.jpg"),
    alt: "sandstone oasis stone",
  },
  sandstoneoasisstonethree: {
    image: require("./projects/Internal/sandstone-oasis-stone-chennai-metro-ltd/sandstone-oasis-stone-3.jpg"),
    alt: "sandstone oasis stone",
  },
  sandstoneoasisstonefour: {
    image: require("./projects/Internal/sandstone-oasis-stone-chennai-metro-ltd/sandstone-oasis-stone-4.jpg"),
    alt: "sandstone oasis stone",
  },
  bricksa308317thumb: {
    image: require("./projects/Internal/garden-club-bricks-a308317/bricks-a308317-thumb.jpg"),
    alt: "bricks a308317",
  },
  bricksa308317one: {
    image: require("./projects/Internal/garden-club-bricks-a308317/bricks-a308317-1.jpg"),
    alt: "bricks a308317",
  },
  bricksa308317two: {
    image: require("./projects/Internal/garden-club-bricks-a308317/bricks-a308317-2.jpg"),
    alt: "bricks a308317",
  },
  travertinecurvedthumb: {
    image: require("./projects/Internal/travertine-pillar-phoenix-marketcity-mall-kurla/travertine-curved-thumb.jpg"),
    alt: "travertine curved",
  },
  travertinecurvedone: {
    image: require("./projects/Internal/travertine-pillar-phoenix-marketcity-mall-kurla/travertine-curved-1.jpg"),
    alt: "travertine curved",
  },
  travertinecurvedtwo: {
    image: require("./projects/Internal/travertine-pillar-phoenix-marketcity-mall-kurla/travertine-curved-2.JPG"),
    alt: "travertine curved",
  },
  travertinecurvedthree: {
    image: require("./projects/Internal/travertine-pillar-phoenix-marketcity-mall-kurla/travertine-curved-3.JPG"),
    alt: "travertine curved",
  },
  woodfarmthumb: {
    image: require("./projects/Internal/wood-farm-house-chikmagalur/wood-farm-house-chikmagalur-thumb.jpg"),
    alt: "wood farm house chikmagalur",
  },
  woodfarmone: {
    image: require("./projects/Internal/wood-farm-house-chikmagalur/wood-farm-house-chikmagalur-1.jpg"),
    alt: "wood farm house chikmagalur",
  },
  woodfarmtwo: {
    image: require("./projects/Internal/wood-farm-house-chikmagalur/wood-farm-house-chikmagalur-2.jpg"),
    alt: "wood farm house chikmagalur",
  },
  woodfarmthree: {
    image: require("./projects/Internal/wood-farm-house-chikmagalur/wood-farm-house-chikmagalur-3.jpg"),
    alt: "wood farm house chikmagalur",
  },
  woodfarmfour: {
    image: require("./projects/Internal/wood-farm-house-chikmagalur/wood-farm-house-chikmagalur-4.jpg"),
    alt: "wood farm house chikmagalur",
  },
  woodfarmfive: {
    image: require("./projects/Internal/wood-farm-house-chikmagalur/wood-farm-house-chikmagalur-5.jpg"),
    alt: "wood farm house chikmagalur",
  },
  woodfarmsix: {
    image: require("./projects/Internal/wood-farm-house-chikmagalur/wood-farm-house-chikmagalur-6.jpg"),
    alt: "wood farm house chikmagalur",
  },
  woodflooringthumb: {
    image: require("./projects/Internal/wood-flooring/wood-original-flooring-thumb.png"),
    alt: "wood original flooring",
  },
  woodflooringone: {
    image: require("./projects/Internal/wood-flooring/wood-original-flooring-thumb_1.png"),
    alt: "wood original flooring",
  },
  woodflooringtwo: {
    image: require("./projects/Internal/wood-flooring/wood-original-flooring-2.jpg"),
    alt: "wood original flooring",
  },
  woodflooringthree: {
    image: require("./projects/Internal/wood-flooring/wood-original-flooring-3.jpg"),
    alt: "wood original flooring",
  },
  woodflooringfour: {
    image: require("./projects/Internal/wood-flooring/wood-original-flooring-4.jpg"),
    alt: "wood original flooring",
  },
  woodflooringfive: {
    image: require("./projects/Internal/wood-flooring/wood-original-flooring-5.jpg"),
    alt: "wood original flooring",
  },
  // External data starts here
  privategardenindonesiathumb: {
    image: require("./projects/External/1-private-garden-indonesia/external-garden-area-thumb.jpg"),
    alt: "external garden area",
  },
  privategardenindonesia1: {
    image: require("./projects/External/1-private-garden-indonesia/external-garden-area-1.jpg"),
    alt: "external garden area",
  },
  privategardenindonesia2: {
    image: require("./projects/External/1-private-garden-indonesia/external-garden-area-2.jpg"),
    alt: "external garden area",
  },
  privategardenindonesia3: {
    image: require("./projects/External/1-private-garden-indonesia/external-garden-area-3.jpg"),
    alt: "external garden area",
  },
  uashhospitalthumb: {
    image: require("./projects/External/2-uash-hospital-maharashtra/Sangli-thumb.jpg"),
    alt: "uash hospital maharashtra",
  },
  uashhospital1: {
    image: require("./projects/External/2-uash-hospital-maharashtra/uash-hospital-maharashtra-1.jpg"),
    alt: "uash hospital maharashtra",
  },
  uashhospital2: {
    image: require("./projects/External/2-uash-hospital-maharashtra/uash-hospital-maharashtra-2.jpg"),
    alt: "uash hospital maharashtra",
  },
  uashhospital3: {
    image: require("./projects/External/2-uash-hospital-maharashtra/uash-hospital-maharashtra-3.jpg"),
    alt: "uash hospital maharashtra",
  },
  uashhospital4: {
    image: require("./projects/External/2-uash-hospital-maharashtra/uash-hospital-maharashtra-4.jpg"),
    alt: "uash hospital maharashtra",
  },
  uashhospital5: {
    image: require("./projects/External/2-uash-hospital-maharashtra/uash-hospital-maharashtra-5.jpg"),
    alt: "uash hospital maharashtra",
  },
  uashhospital6: {
    image: require("./projects/External/2-uash-hospital-maharashtra/uash-hospital-maharashtra-6.jpg"),
    alt: "uash hospital maharashtra",
  },
  uashhospital7: {
    image: require("./projects/External/2-uash-hospital-maharashtra/uash-hospital-maharashtra-7.jpg"),
    alt: "uash hospital maharashtra",
  },
  foodmallexpresswaythumb: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-thumb.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway1: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-1.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway2: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-2.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway3: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-3.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway4: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-4.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway5: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-5.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway6: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-6.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway7: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-7.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway8: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-8.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway9: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-9.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway10: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-10.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  foodmallexpressway11: {
    image: require("./projects/External/3-foodmall-mumbai-pune-expressway/foodmall-mumbai-pune-expressway-11.jpg"),
    alt: "foodmall mumbai pune expressway",
  },
  shriomkarthumb: {
    image: require("./projects/External/4-shri-omkar-developer-mumbai/shri-omkar-developer-mumbai-thumb.jpg"),
    alt: "shri omkar developer mumbai",
  },
  shriomkar1: {
    image: require("./projects/External/4-shri-omkar-developer-mumbai/shri-omkar-developer-mumbai-1.jpg"),
    alt: "shri omkar developer mumbai",
  },
  shriomkar2: {
    image: require("./projects/External/4-shri-omkar-developer-mumbai/shri-omkar-developer-mumbai-2.jpg"),
    alt: "shri omkar developer mumbai",
  },
  shriomkar3: {
    image: require("./projects/External/4-shri-omkar-developer-mumbai/shri-omkar-developer-mumbai-3.jpg"),
    alt: "shri omkar developer mumbai",
  },
  shriomkar4: {
    image: require("./projects/External/4-shri-omkar-developer-mumbai/shri-omkar-developer-mumbai-4.JPG"),
    alt: "shri omkar developer mumbai",
  },
  shriomkar5: {
    image: require("./projects/External/4-shri-omkar-developer-mumbai/shri-omkar-developer-mumbai-5.jpg"),
    alt: "shri omkar developer mumbai",
  },
  shriomkar6: {
    image: require("./projects/External/4-shri-omkar-developer-mumbai/shri-omkar-developer-mumbai-6.jpg"),
    alt: "shri omkar developer mumbai",
  },
  sumitwoodsthumb: {
    image: require("./projects/External/5-sumit-woods-mumbai/sumit-woods-mumbai-thumb.jpg"),
    alt: "sumit woods mumbai",
  },
  sumitwoods1: {
    image: require("./projects/External/5-sumit-woods-mumbai/sumit-woods-mumbai-1.jpg"),
    alt: "sumit woods mumbai",
  },
  sumitwoods2: {
    image: require("./projects/External/5-sumit-woods-mumbai/sumit-woods-mumbai-2.jpg"),
    alt: "sumit woods mumbai",
  },
  sumitwoods3: {
    image: require("./projects/External/5-sumit-woods-mumbai/sumit-woods-mumbai-3.jpg"),
    alt: "sumit woods mumbai",
  },
  sumitwoods4: {
    image: require("./projects/External/5-sumit-woods-mumbai/sumit-woods-mumbai-4.jpg"),
    alt: "sumit woods mumbai",
  },
  privatevillacrossardmushroomthumb: {
    image: require("./projects/External/6-private-villa-crossard-mushroom-stone/private-villa-crossard-mushroom-stone-thumb.jpg"),
    alt: "private villa crossard mushroom stone",
  },
  privatevillacrossardmushroom1: {
    image: require("./projects/External/6-private-villa-crossard-mushroom-stone/private-villa-crossard-mushroom-stone-1.jpg"),
    alt: "private villa crossard mushroom stone",
  },
  privatevillacrossardmushroom2: {
    image: require("./projects/External/6-private-villa-crossard-mushroom-stone/private-villa-crossard-mushroom-stone-2.jpg"),
    alt: "private villa crossard mushroom stone",
  },
  hangerfashionoutletthumb: {
    image: require("./projects/External/7-the-hanger-fashion-outlet-indonesia/hanger-fashion-outlet-indonesia-thumb.jpg"),
    alt: "hanger fashion outlet indonesia",
  },
  hangerfashionoutlet1: {
    image: require("./projects/External/7-the-hanger-fashion-outlet-indonesia/hanger-fashion-outlet-indonesia-1.jpg"),
    alt: "hanger fashion outlet indonesia",
  },
  hangerfashionoutlet2: {
    image: require("./projects/External/7-the-hanger-fashion-outlet-indonesia/hanger-fashion-outlet-indonesia-2.jpg"),
    alt: "hanger fashion outlet indonesia",
  },
  hangerfashionoutlet3: {
    image: require("./projects/External/7-the-hanger-fashion-outlet-indonesia/hanger-fashion-outlet-indonesia-3.jpg"),
    alt: "hanger fashion outlet indonesia",
  },
  hangerfashionoutlet4: {
    image: require("./projects/External/7-the-hanger-fashion-outlet-indonesia/hanger-fashion-outlet-indonesia-4.jpg"),
    alt: "hanger fashion outlet indonesia",
  },
  hangerfashionoutlet5: {
    image: require("./projects/External/7-the-hanger-fashion-outlet-indonesia/hanger-fashion-outlet-indonesia-5.jpg"),
    alt: "hanger fashion outlet indonesia",
  },
  bricksgseriesresidentialthumb: {
    image: require("./projects/External/8-bricks-g-series-residential-building/bricks-g-series-residential-building-thumb.jpg"),
    alt: "bricks g series residential building",
  },
  bricksgseriesresidential1: {
    image: require("./projects/External/8-bricks-g-series-residential-building/bricks-g-series-residential-building-1.JPG"),
    alt: "bricks g series residential building",
  },
  bricksgseriesresidential2: {
    image: require("./projects/External/8-bricks-g-series-residential-building/bricks-g-series-residential-building-2.JPG"),
    alt: "bricks g series residential building",
  },
  balajimedicalcentrethumb: {
    image: require("./projects/External/9-balaji-medical-centre-chennai/balaji-medical-centre-chennai-thumb.jpg"),
    alt: "balaji medical centre chennai",
  },
  balajimedicalcentre1: {
    image: require("./projects/External/9-balaji-medical-centre-chennai/balaji-medical-centre-chennai-1.jpg"),
    alt: "balaji medical centre chennai",
  },
  balajimedicalcentre2: {
    image: require("./projects/External/9-balaji-medical-centre-chennai/balaji-medical-centre-chennai-2.jpg"),
    alt: "balaji medical centre chennai",
  },
  balajimedicalcentre3: {
    image: require("./projects/External/9-balaji-medical-centre-chennai/balaji-medical-centre-chennai-3.jpg"),
    alt: "balaji medical centre chennai",
  },
  balajimedicalcentre4: {
    image: require("./projects/External/9-balaji-medical-centre-chennai/balaji-medical-centre-chennai-4.jpg"),
    alt: "balaji medical centre chennai",
  },
  brtspunethumb: {
    image: require("./projects/External/10-brts-pune/brts-pune-thumb.jpg"),
    alt: "brts pune",
  },
  brtspune1: {
    image: require("./projects/External/10-brts-pune/brts-pune-1.JPG"),
    alt: "brts pune",
  },
  brtspune2: {
    image: require("./projects/External/10-brts-pune/brts-pune-2.JPG"),
    alt: "brts pune",
  },
  brtspune3: {
    image: require("./projects/External/10-brts-pune/brts-pune-3.JPG"),
    alt: "brts pune",
  },
  brtspune4: {
    image: require("./projects/External/10-brts-pune/brts-pune-4.JPG"),
    alt: "brts pune",
  },
  hyattcentricgoathumb: {
    image: require("./projects/External/11-hyatt-centric-goa/hyatt-centric-goa-thumb.jpg"),
    alt: "hyatt centric goa",
  },
  hyattcentricgoa1: {
    image: require("./projects/External/11-hyatt-centric-goa/hyatt-centric-goa-1.jpg"),
    alt: "hyatt centric goa",
  },
  hyattcentricgoa2: {
    image: require("./projects/External/11-hyatt-centric-goa/hyatt-centric-goa-2.jpg"),
    alt: "hyatt centric goa",
  },
  hyattcentricgoa3: {
    image: require("./projects/External/11-hyatt-centric-goa/hyatt-centric-goa-3.jpg"),
    alt: "hyatt centric goa",
  },
  bricksk326c4mthumb: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-thumb.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  bricksk326c4m1: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-1.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  bricksk326c4m2: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-2.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  bricksk326c4m3: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-3.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  bricksk326c4m4: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-4.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  bricksk326c4m5: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-5.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  bricksk326c4m6: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-6.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  bricksk326c4m7: {
    image: require("./projects/External/12-bricks-k326c4-residential-building-mumbai/bricks-k326c4-residential-building-mumbai-7.jpg"),
    alt: "bricks k326c4 residential building mumbai",
  },
  privatevillarockfacestonecladdingthumb: {
    image: require("./projects/External/13-private-villa-rockface-stone-cladding/private-villa-rockface-stone-cladding-thumb.jpg"),
    alt: "private villa rockface stone cladding",
  },
  privatevillarockfacestonecladding1: {
    image: require("./projects/External/13-private-villa-rockface-stone-cladding/private-villa-rockface-stone-cladding-1.jpg"),
    alt: "private villa rockface stone cladding",
  },
  privatevillarockfacestonecladding2: {
    image: require("./projects/External/13-private-villa-rockface-stone-cladding/private-villa-rockface-stone-cladding-2.jpg"),
    alt: "private villa rockface stone cladding",
  },
  privatevillarockfacestonecladding3: {
    image: require("./projects/External/13-private-villa-rockface-stone-cladding/private-villa-rockface-stone-cladding-3.jpg"),
    alt: "private villa rockface stone cladding",
  },
  privatevillarockfacestonecladding4: {
    image: require("./projects/External/13-private-villa-rockface-stone-cladding/private-villa-rockface-stone-cladding-4.jpg"),
    alt: "private villa rockface stone cladding",
  },
  oasisstonewallthumb: {
    image: require("./projects/External/14-oasis-stone-compound-wall-cladding-mumbai/oasis-stone-compound-wall-cladding-mumbai-thumb.jpg"),
    alt: "oasis stone compound wall",
  },
  oasisstonewall1: {
    image: require("./projects/External/14-oasis-stone-compound-wall-cladding-mumbai/oasis-stone-compound-wall-cladding-mumbai-1.jpg"),
    alt: "oasis stone compound wall",
  },
  oasisstonewall2: {
    image: require("./projects/External/14-oasis-stone-compound-wall-cladding-mumbai/oasis-stone-compound-wall-cladding-mumbai-2.jpg"),
    alt: "oasis stone compound wall",
  },
  oasisstonewall3: {
    image: require("./projects/External/14-oasis-stone-compound-wall-cladding-mumbai/oasis-stone-compound-wall-cladding-mumbai-3.jpg"),
    alt: "oasis stone compound wall",
  },
  oasisstonewall4: {
    image: require("./projects/External/14-oasis-stone-compound-wall-cladding-mumbai/oasis-stone-compound-wall-cladding-mumbai-4.jpg"),
    alt: "oasis stone compound wall",
  },
  bricksk326c4bengaluruthumb: {
    image: require("./projects/External/15-bricks-k326c4-private-residence-bengaluru/bricks-k326c4-private-residence-bengaluru-thumb.jpg"),
    alt: "bricks k326c4 private residence bengaluru",
  },
  bricksk326c4bengaluru1: {
    image: require("./projects/External/15-bricks-k326c4-private-residence-bengaluru/bricks-k326c4-private-residence-bengaluru-1.jpg"),
    alt: "bricks k326c4 private residence bengaluru",
  },
  bricksk326c4bengaluru2: {
    image: require("./projects/External/15-bricks-k326c4-private-residence-bengaluru/bricks-k326c4-private-residence-bengaluru-2.jpg"),
    alt: "bricks k326c4 private residence bengaluru",
  },
  bricksk326c4bengaluru3: {
    image: require("./projects/External/15-bricks-k326c4-private-residence-bengaluru/bricks-k326c4-private-residence-bengaluru-3.jpg"),
    alt: "bricks k326c4 private residence bengaluru",
  },
  bricksk326c4bengaluru4: {
    image: require("./projects/External/15-bricks-k326c4-private-residence-bengaluru/bricks-k326c4-private-residence-bengaluru-4.jpg"),
    alt: "bricks k326c4 private residence bengaluru",
  },
  bricksk326c4bengaluru5: {
    image: require("./projects/External/15-bricks-k326c4-private-residence-bengaluru/bricks-k326c4-private-residence-bengaluru-5.jpg"),
    alt: "bricks k326c4 private residence bengaluru",
  },
  oasisstonehyderabadthumb: {
    image: require("./projects/External/16-oasis-stone-commercial-complex-hyderabad/oasis-stone-commercial-complex-hyderabad-thumb.jpg"),
    alt: "oasis stone commercial complex hyderabad",
  },
  oasisstonehyderabad1: {
    image: require("./projects/External/16-oasis-stone-commercial-complex-hyderabad/oasis-stone-commercial-complex-hyderabad-1.JPG"),
    alt: "oasis stone commercial complex hyderabad",
  },
  oasisstonehyderabad2: {
    image: require("./projects/External/16-oasis-stone-commercial-complex-hyderabad/oasis-stone-commercial-complex-hyderabad-2.JPG"),
    alt: "oasis stone commercial complex hyderabad",
  },
  oasisstonehyderabad3: {
    image: require("./projects/External/16-oasis-stone-commercial-complex-hyderabad/oasis-stone-commercial-complex-hyderabad-3.JPG"),
    alt: "oasis stone commercial complex hyderabad",
  },
  oasisstonehyderabad4: {
    image: require("./projects/External/16-oasis-stone-commercial-complex-hyderabad/oasis-stone-commercial-complex-hyderabad-4.JPG"),
    alt: "oasis stone commercial complex hyderabad",
  },
  clickhotelthumb: {
    image: require("./projects/External/17-click-hotel-bengaluru/click-hotel-bengaluru-thumb.jpg"),
    alt: "click hotel bengaluru",
  },
  clickhotel1: {
    image: require("./projects/External/17-click-hotel-bengaluru/click-hotel-bengaluru-1.JPG"),
    alt: "click hotel bengaluru",
  },
  clickhotel2: {
    image: require("./projects/External/17-click-hotel-bengaluru/click-hotel-bengaluru-2.JPG"),
    alt: "click hotel bengaluru",
  },
  clickhotel3: {
    image: require("./projects/External/17-click-hotel-bengaluru/click-hotel-bengaluru-3.JPG"),
    alt: "click hotel bengaluru",
  },
  clickhotel4: {
    image: require("./projects/External/17-click-hotel-bengaluru/click-hotel-bengaluru-4.JPG"),
    alt: "click hotel bengaluru",
  },
  homebhoojathumb: {
    image: require("./projects/External/18-home-bhooja-hyderabad/home-bhooja-hyderabad-thumb.jpg"),
    alt: "home bhooja hyderabad",
  },
  homebhooja1: {
    image: require("./projects/External/18-home-bhooja-hyderabad/home-bhooja-hyderabad-1.jpg"),
    alt: "home bhooja hyderabad",
  },
  homebhooja2: {
    image: require("./projects/External/18-home-bhooja-hyderabad/home-bhooja-hyderabad-2.jpg"),
    alt: "home bhooja hyderabad",
  },
  homebhooja3: {
    image: require("./projects/External/18-home-bhooja-hyderabad/home-bhooja-hyderabad-3.jpg"),
    alt: "home bhooja hyderabad",
  },
  homebhooja4: {
    image: require("./projects/External/18-home-bhooja-hyderabad/home-bhooja-hyderabad-4.jpg"),
    alt: "home bhooja hyderabad",
  },
  homebhooja5: {
    image: require("./projects/External/18-home-bhooja-hyderabad/home-bhooja-hyderabad-5.jpg"),
    alt: "home bhooja hyderabad",
  },
  homebhooja6: {
    image: require("./projects/External/18-home-bhooja-hyderabad/home-bhooja-hyderabad-6.jpg"),
    alt: "home bhooja hyderabad",
  },
  happyhomethumb: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-thumb.jpg"),
    alt: "happy home mumbai",
  },
  happyhome1: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-1.jpg"),
    alt: "happy home mumbai",
  },
  happyhome2: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-2.jpg"),
    alt: "happy home mumbai",
  },
  happyhome3: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-3.jpg"),
    alt: "happy home mumbai",
  },
  happyhome4: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-4.jpg"),
    alt: "happy home mumbai",
  },
  happyhome5: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-5.jpg"),
    alt: "happy home mumbai",
  },
  happyhome6: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-6.jpg"),
    alt: "happy home mumbai",
  },
  happyhome7: {
    image: require("./projects/External/19-happy-home-mumbai/happy-home-mumbai-7.jpg"),
    alt: "happy home mumbai",
  },
  commercialoasisthumb: {
    image: require("./projects/External/20-commercial-site-oasis-stone/commercial-site-oasis-stone-thumb.jpg"),
    alt: "commercial site oasis stone",
  },
  commercialoasis1: {
    image: require("./projects/External/20-commercial-site-oasis-stone/commercial-site-oasis-stone-1.jpg"),
    alt: "commercial site oasis stone",
  },
  commercialoasis2: {
    image: require("./projects/External/20-commercial-site-oasis-stone/commercial-site-oasis-stone-2.jpg"),
    alt: "commercial site oasis stone",
  },
  privatevillaoasisstonethumb: {
    image: require("./projects/External/21-private-villa-oasis-stone/private-villa-oasis-stone-thumb.jpg"),
    alt: "private villa oasis stone",
  },
  privatevillaoasisstone1: {
    image: require("./projects/External/21-private-villa-oasis-stone/private-villa-oasis-stone-1.jpg"),
    alt: "private villa oasis stone",
  },
  privatevillaoasisstone2: {
    image: require("./projects/External/21-private-villa-oasis-stone/private-villa-oasis-stone-2.jpg"),
    alt: "private villa oasis stone",
  },
  privatevillaoasisstone3: {
    image: require("./projects/External/21-private-villa-oasis-stone/private-villa-oasis-stone-3.jpg"),
    alt: "private villa oasis stone",
  },
  slatesunnyewoodthumb: {
    image: require("./projects/External/22-slate-sunnye-wood-private-villa-hyderabad/slate-sunnye-wood-private-villa-hyderabad-thumb.jpg"),
    alt: "slate sunnye wood private villa hyderabad",
  },
  slatesunnyewood1: {
    image: require("./projects/External/22-slate-sunnye-wood-private-villa-hyderabad/slate-sunnye-wood-private-villa-hyderabad-1.jpg"),
    alt: "slate sunnye wood private villa hyderabad",
  },
  slatesunnyewood2: {
    image: require("./projects/External/22-slate-sunnye-wood-private-villa-hyderabad/slate-sunnye-wood-private-villa-hyderabad-2.jpg"),
    alt: "slate sunnye wood private villa hyderabad",
  },
  slatesunnyewood3: {
    image: require("./projects/External/22-slate-sunnye-wood-private-villa-hyderabad/slate-sunnye-wood-private-villa-hyderabad-3.JPG"),
    alt: "slate sunnye wood private villa hyderabad",
  },
  piecestackstonethumb: {
    image: require("./projects/External/23-35-piece-stack-stone-residential-site-north-east/35-piece-stack-stone-residential-site-north-east-thumb.jpg"),
    alt: "35 piece stack stone residential site north east",
  },
  piecestackstone1: {
    image: require("./projects/External/23-35-piece-stack-stone-residential-site-north-east/35-piece-stack-stone-residential-site-north-east-1.jpg"),
    alt: "35 piece stack stone residential site north east",
  },
  piecestackstone2: {
    image: require("./projects/External/23-35-piece-stack-stone-residential-site-north-east/35-piece-stack-stone-residential-site-north-east-2.jpg"),
    alt: "35 piece stack stone residential site north east",
  },
  piecestackstone3: {
    image: require("./projects/External/23-35-piece-stack-stone-residential-site-north-east/35-piece-stack-stone-residential-site-north-east-3.JPG"),
    alt: "35 piece stack stone residential site north east",
  },
  piecestackstone4: {
    image: require("./projects/External/23-35-piece-stack-stone-residential-site-north-east/35-piece-stack-stone-residential-site-north-east-4.jpg"),
    alt: "35 piece stack stone residential site north east",
  },
  ornatehotelthumb: {
    image: require("./projects/External/24-ornate-hotel-travertine/ornate-hotel-travertine-thumb.jpg"),
    alt: "ornate hotel travertine",
  },
  ornatehotel1: {
    image: require("./projects/External/24-ornate-hotel-travertine/ornate-hotel-travertine-1.JPG"),
    alt: "ornate hotel travertine",
  },
  ornatehotel2: {
    image: require("./projects/External/24-ornate-hotel-travertine/ornate-hotel-travertine-2.JPG"),
    alt: "ornate hotel travertine",
  },
  ornatehotel3: {
    image: require("./projects/External/24-ornate-hotel-travertine/ornate-hotel-travertine-3.JPG"),
    alt: "ornate hotel travertine",
  },
  infinitistone043thumb: {
    image: require("./projects/External/25-infiniti-stone-043-private-residence/infiniti-stone-043-private-residence-thumb.jpg"),
    alt: "infiniti stone 043 private residence",
  },
  infinitistone0431: {
    image: require("./projects/External/25-infiniti-stone-043-private-residence/infiniti-stone-043-private-residence-1.jpg"),
    alt: "infiniti stone 043 private residence",
  },
  infinitistone0432: {
    image: require("./projects/External/25-infiniti-stone-043-private-residence/infiniti-stone-043-private-residence-2.jpg"),
    alt: "infiniti stone 043 private residence",
  },
  infinitistone0433: {
    image: require("./projects/External/25-infiniti-stone-043-private-residence/infiniti-stone-043-private-residence-3.jpg"),
    alt: "infiniti stone 043 private residence",
  },
  gseriesfacingthumb: {
    image: require("./projects/External/26-g-series-facing-bricks-residential-site/g-series-facing-bricks-residential-site-thumb.jpg"),
    alt: "g series facing bricks residential",
  },
  gseriesfacing1: {
    image: require("./projects/External/26-g-series-facing-bricks-residential-site/g-series-facing-bricks-residential-site-1.jpg"),
    alt: "g series facing bricks residential",
  },
  gseriesfacing2: {
    image: require("./projects/External/26-g-series-facing-bricks-residential-site/g-series-facing-bricks-residential-site-2.jpg"),
    alt: "g series facing bricks residential",
  },
  concertojaipurthumb: {
    image: require("./projects/External/27-oasis-stone-concerto-private-residence-jaipur/oasis-stone-concerto-private-residence-jaipur-thumb.jpg"),
    alt: "oasis stone concerto private residence jaipur",
  },
  concertojaipur1: {
    image: require("./projects/External/27-oasis-stone-concerto-private-residence-jaipur/oasis-stone-concerto-private-residence-jaipur-1.jpg"),
    alt: "oasis stone concerto private residence jaipur",
  },
  concertojaipur2: {
    image: require("./projects/External/27-oasis-stone-concerto-private-residence-jaipur/oasis-stone-concerto-private-residence-jaipur-2.jpg"),
    alt: "oasis stone concerto private residence jaipur",
  },
  concertojaipur3: {
    image: require("./projects/External/27-oasis-stone-concerto-private-residence-jaipur/oasis-stone-concerto-private-residence-jaipur-3.jpg"),
    alt: "oasis stone concerto private residence jaipur",
  },
  concertojaipur4: {
    image: require("./projects/External/27-oasis-stone-concerto-private-residence-jaipur/oasis-stone-concerto-private-residence-jaipur-4.jpg"),
    alt: "oasis stone concerto private residence jaipur",
  },
  appaswamybloomingdalethumb: {
    image: require("./projects/External/28-bricks-k326c4-appaswamy-bloomingdale/bricks-k326c4-appaswamy-bloomingdale-thumb.jpg"),
    alt: "bricks k326c4 appaswamy bloomingdale",
  },
  appaswamybloomingdale1: {
    image: require("./projects/External/28-bricks-k326c4-appaswamy-bloomingdale/bricks-k326c4-appaswamy-bloomingdale-1.jpg"),
    alt: "bricks k326c4 appaswamy bloomingdale",
  },
  appaswamybloomingdale2: {
    image: require("./projects/External/28-bricks-k326c4-appaswamy-bloomingdale/bricks-k326c4-appaswamy-bloomingdale-2.jpg"),
    alt: "bricks k326c4 appaswamy bloomingdale",
  },
  appaswamybloomingdale3: {
    image: require("./projects/External/28-bricks-k326c4-appaswamy-bloomingdale/bricks-k326c4-appaswamy-bloomingdale-3.jpg"),
    alt: "bricks k326c4 appaswamy bloomingdale",
  },
  appaswamybloomingdale4: {
    image: require("./projects/External/28-bricks-k326c4-appaswamy-bloomingdale/bricks-k326c4-appaswamy-bloomingdale-4.jpg"),
    alt: "bricks k326c4 appaswamy bloomingdale",
  },
  appaswamybloomingdale5: {
    image: require("./projects/External/28-bricks-k326c4-appaswamy-bloomingdale/bricks-k326c4-appaswamy-bloomingdale-5.jpg"),
    alt: "bricks k326c4 appaswamy bloomingdale",
  },
  oasisfogethumb: {
    image: require("./projects/External/29-oasis-foge-private-residence/oasis-foge-private-residence-thumb.jpg"),
    alt: "oasis foge private residence",
  },
  oasisfoge1: {
    image: require("./projects/External/29-oasis-foge-private-residence/oasis-foge-private-residence-1.jpg"),
    alt: "oasis foge private residence",
  },
  oasisfoge2: {
    image: require("./projects/External/29-oasis-foge-private-residence/oasis-foge-private-residence-2.jpg"),
    alt: "oasis foge private residence",
  },
  oasisfoge3: {
    image: require("./projects/External/29-oasis-foge-private-residence/oasis-foge-private-residence-3.jpg"),
    alt: "oasis foge private residence",
  },
  oasisfoge4: {
    image: require("./projects/External/29-oasis-foge-private-residence/oasis-foge-private-residence-4.jpg"),
    alt: "oasis foge private residence",
  },
  oasisfoge5: {
    image: require("./projects/External/29-oasis-foge-private-residence/oasis-foge-private-residence-5.jpg"),
    alt: "oasis foge private residence",
  },
  slateartethumb: {
    image: require("./projects/External/30-slate-arte-aera-site/slate-arte-aera-site-thumb.jpg"),
    alt: "slate arte aera site",
  },
  slatearte1: {
    image: require("./projects/External/30-slate-arte-aera-site/slate-arte-aera-site-1.JPG"),
    alt: "slate arte aera site",
  },
  slatearte2: {
    image: require("./projects/External/30-slate-arte-aera-site/slate-arte-aera-site-2.JPG"),
    alt: "slate arte aera site",
  },
  slatearte3: {
    image: require("./projects/External/30-slate-arte-aera-site/slate-arte-aera-site-3.JPG"),
    alt: "slate arte aera site",
  },
  slatearte4: {
    image: require("./projects/External/30-slate-arte-aera-site/slate-arte-aera-site-4.JPG"),
    alt: "slate arte aera site",
  },
  slatearte5: {
    image: require("./projects/External/30-slate-arte-aera-site/slate-arte-aera-site-5.JPG"),
    alt: "slate arte aera site",
  },
  slatearte6: {
    image: require("./projects/External/30-slate-arte-aera-site/slate-arte-aera-site-6.JPG"),
    alt: "slate arte aera site",
  },
  sandstonethumb: {
    image: require("./projects/External/31-sandstone-commercial-site/sandstone-commercial-site-thumb.jpg"),
    alt: "sandstone commercial site",
  },
  sandstone1: {
    image: require("./projects/External/31-sandstone-commercial-site/sandstone-commercial-site-1.jpg"),
    alt: "sandstone commercial site",
  },
  sandstone2: {
    image: require("./projects/External/31-sandstone-commercial-site/sandstone-commercial-site-2.jpg"),
    alt: "sandstone commercial site",
  },
  sandstone3: {
    image: require("./projects/External/31-sandstone-commercial-site/sandstone-commercial-site-3.jpg"),
    alt: "sandstone commercial site",
  },
  sandstone4: {
    image: require("./projects/External/31-sandstone-commercial-site/sandstone-commercial-site-4.jpg"),
    alt: "sandstone commercial site",
  },
  travertine052kzthumb: {
    image: require("./projects/External/32-travertine-052kz-external-cladding/travertine-052kz-external-cladding-thumb.jpg"),
    alt: "travertine 052kz external cladding",
  },
  travertine052kz1: {
    image: require("./projects/External/32-travertine-052kz-external-cladding/travertine-052kz-external-cladding-1.jpg"),
    alt: "travertine 052kz external cladding",
  },
  travertine052kz2: {
    image: require("./projects/External/32-travertine-052kz-external-cladding/travertine-052kz-external-cladding-2.jpg"),
    alt: "travertine 052kz external cladding",
  },
  travertine052kz3: {
    image: require("./projects/External/32-travertine-052kz-external-cladding/travertine-052kz-external-cladding-3.jpg"),
    alt: "travertine 052kz external cladding",
  },
  slatearteprivatehyderabadthumb: {
    image: require("./projects/External/33-slate-arte-private-villa-hyderabad/slate-arte-private-villa-hyderabad-thumb.jpg"),
    alt: "slate arte private villa hyderabad",
  },
  slatearteprivatehyderabad1: {
    image: require("./projects/External/33-slate-arte-private-villa-hyderabad/slate-arte-private-villa-hyderabad-1.JPG"),
    alt: "slate arte private villa hyderabad",
  },
  slatearteprivatehyderabad2: {
    image: require("./projects/External/33-slate-arte-private-villa-hyderabad/slate-arte-private-villa-hyderabad-2.JPG"),
    alt: "slate arte private villa hyderabad",
  },
  slatearteprivatehyderabad3: {
    image: require("./projects/External/33-slate-arte-private-villa-hyderabad/slate-arte-private-villa-hyderabad-3.JPG"),
    alt: "slate arte private villa hyderabad",
  },
  slatearteprivatehyderabad4: {
    image: require("./projects/External/33-slate-arte-private-villa-hyderabad/slate-arte-private-villa-hyderabad-4.JPG"),
    alt: "slate arte private villa hyderabad",
  },
  travertine052kzatharvathumb: {
    image: require("./projects/External/34-travertine-052kz-bricks-atharva-site/travertine-052kz-bricks-atharva-site-thumb.jpg"),
    alt: "travertine 052kz bricks atharva site",
  },
  travertine052kzatharva1: {
    image: require("./projects/External/34-travertine-052kz-bricks-atharva-site/travertine-052kz-bricks-atharva-site-1.jpg"),
    alt: "travertine 052kz bricks atharva site",
  },
  travertine052kzatharva2: {
    image: require("./projects/External/34-travertine-052kz-bricks-atharva-site/travertine-052kz-bricks-atharva-site-2.JPG"),
    alt: "travertine 052kz bricks atharva site",
  },
  travertine052kzatharva3: {
    image: require("./projects/External/34-travertine-052kz-bricks-atharva-site/travertine-052kz-bricks-atharva-site-3.JPG"),
    alt: "travertine 052kz bricks atharva site",
  },
  travertine052kzatharva4: {
    image: require("./projects/External/34-travertine-052kz-bricks-atharva-site/travertine-052kz-bricks-atharva-site-4.JPG"),
    alt: "travertine 052kz bricks atharva site",
  },
  travertine052kzatharva5: {
    image: require("./projects/External/34-travertine-052kz-bricks-atharva-site/travertine-052kz-bricks-atharva-site-5.JPG"),
    alt: "travertine 052kz bricks atharva site",
  },
  travertine052kzatharva6: {
    image: require("./projects/External/34-travertine-052kz-bricks-atharva-site/travertine-052kz-bricks-atharva-site-6.jpg"),
    alt: "travertine 052kz bricks atharva site",
  },
  contactusbanner: {
    image: require("./contact/bannerimgcontact.jpg"),
    alt: "contact banner",
  },
  downloadbanner: {
    image: require("./downloads/download-banner.jpg"),
    alt: "download banner",
  },
  eventbanner: {
    image: require("./events/event-banner.jpg"),
    alt: "event banner",
  },

  // External data ends here
};
export let projectCategoriesImg = {
  internal_cladding: {
    image: require("./projects/project-cat1.png"),
    alt: "External 1",
  },
  internal_cladding_banner: {
    image: require("./projects/internal_cladding_banner.jpg"),
    alt: "Internal 1",
  },
  external_cladding: {
    image: require("./projects/project-cat2.png"),
    alt: "External 2",
  },
  external_cladding_banner: {
    image: require("./projects/External_Cladding_Banner.jpg"),
    alt: "External 1",
  },
};

export let projectsImg = {
  hale_warehouse_mint_plaza: {
    image: require("./projects/projectimg1.png"),
    alt: "",
  },
  hale_warehouse_mint_plaza_banner: {
    image: require("./projects/bannerimg.jpg"),
    alt: "",
  },
  hale_warehouse_mint_plaza_gallery_1: {
    image: require("./projects/projectdetail.png"),
    alt: "",
  },
  potrero_launch_third_street: {
    image: require("./projects/projectimg2.png"),
    alt: "",
  },
  potrero_launch_third_street_banner: {
    image: require("./projects/bannerimg.jpg"),
    alt: "",
  },
};
export let productsImg = {
  cutstone_362: {
    image: require("./products/productimg1.png"),
    alt: "",
  },
  cutstone_363: {
    image: require("./products/productimg3.png"),
    alt: "",
  },
};
