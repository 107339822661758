import React, { useState, useEffect } from "react";
import "./style.scss";
//components
import Banner from "../../components/Banner/Banner";

import useWindowDimensions from "../../hooks/useWindowDimensions";

//images
import calllogo from "../../images/contact/calllogo.svg";
import emaillogo from "../../images/contact/emaillogo.svg";
import {
  facebook,
  linkedin,
  insta,
  locationicon,
  youtube,
  images,
  twitterX,
  pinterest,
} from "../../images";
import { fbpath, instapath, linkedinpath, pinterestpath, twitterpath } from "../../helpers/paths";

import http from "../../helpers/http";

import config from "../../config/config";
import Joi from "joi";
import _ from "lodash";

const ContactUs = (props) => {
  const productsData = [
    { category: "Bricks", title: "A Series Facing Bricks" },
    { category: "Bricks", title: "K Series Facing Bricks" },
    { category: "Bricks", title: "G Series Facing Bricks" },
    { category: "Bricks", title: "R Series Facing Bricks" },
    { category: "Stone", title: "MCM Travertine Stone" },
    { category: "Stone", title: "MCM Slate Stone" },
    { category: "Stone", title: "MCM Oasis Stone" },
    { category: "Stone", title: "MCM Cutstone" },
    { category: "Stone", title: "MCM Infiniti Stone" },
    { category: "Stone", title: "MCM 35 Piece Stack Stone" },
    { category: "Stone", title: "MCM Granite Stone" },
    { category: "Stone", title: "MCM Crossard Mushroom Stone" },
    { category: "Stone", title: "MCM Devine Mushroom" },
    { category: "Wood", title: "MCM Original Wood Suez" },
    { category: "Wood", title: "MCM Original Wood Rhine" },
    { category: "Wood", title: "MCM Original Wood Volga" },
    { category: "Wood", title: "MCM Jagged Wood Olympus" },
  ];

  const { width } = useWindowDimensions();

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    type: "Bricks",
    product: "A Series Facing Bricks",
    state: "",
    city: "",
    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");
  const [types, setTypes] = useState(["Bricks", "Stone", "Wood"]);
  const [products, setProducts] = useState(
    _.filter(productsData, { category: "Bricks" })
  );
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);


  let base_url = config.api_url + "/forms/contact";

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Phone"),
    type: Joi.string().trim().required().label("Product Type"),
    product: Joi.string().trim().required().label("Product"),
    state: Joi.string().trim().required().label("State"),
    city: Joi.string().trim().required().label("City"),

    message: Joi.string().trim().required().label("Detail"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, {
      ...values,
      phone: `${countryCode}${values.phone}`,
    });

    if (data) {
      // redirect
      // navigate("/thank-you");
      props.history.push("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const fetchCodes = async () => {
    const data = await http.get(`${config.api_url}/general/countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data.data);
    }
  };
  const fetchStates = async (country_id) => {
    const data = await http.get(`${config.api_url}/general/states/${country_id}`);
    // console.log(data);
    if (data) {
      setStates(data.data);
      fetchCities(data.data[0].id);
      
    }
  };
  const fetchCities = async (state_id) => {
    const data = await http.get(`${config.api_url}/general/cities/${state_id}`);
    // console.log(data);
    if (data) {
      setCities(data.data);
      
    }
  };

  useEffect(() => {
    fetchCodes();
    fetchStates(101);
  }, []);

  const productsList = products.map((item, i) => (
    <option key={i} value={item.title}>
      {item.title}
    </option>
  ));
  console.log({ products });
  return (
    <>
      <Banner
        imgSrc={images.contactusbanner.image}
        bannerTitle="contact us"
        breadcrumb2="contact us"
        disabledBreadcrumb2
      />
      <section className="contactsec2">
        <div className="contact_details_flex">
          <div className="contact_details_flex_left">
            <h3>contact us</h3>
            <ul className="contact_details_wrapper">
              <li></li>
              <li className="contact_details_list_item">
                <img
                  src={locationicon}
                  alt="MCM contact details logo"
                  className="contact_details_logo location_icon"
                  loading="lazy"
                />

                <p className="add_desc">
                  <span className="mcm_text">
                    {" "}
                    MCMFlexi Cladding India Pvt. Ltd{" "}
                  </span>
                  Damji Shamji Udyog Bhavan, 101-103, Veera Desai Rd, Andheri
                  West, Mumbai, Maharashtra 400053.
                </p>
              </li>
              <li className="contact_details_list_item ">
                <img
                  src={calllogo}
                  alt="MCM contact details logo"
                  className="contact_details_logo call_icon"
                  loading="lazy"
                />
                <p>
                  {/* <a href="tel:+912226735084">+91 22 2673 5084 &nbsp;&nbsp; | &nbsp;&nbsp;</a>{" "}
                  {width > 767 ? null : <br />} */}
                  <a href="tel:+917045905068 "> +91 7045905068 </a>
                  <br />
                  <a href="tel:+918291900355 "> +91 8291900355 </a>
                  {/* <br />
                  <a href="tel:+91 9821074177">+91 9821074177</a> */}
                </p>
              </li>
              <li className="contact_details_list_item">
                <img
                  src={emaillogo}
                  alt="MCM contact details logo"
                  className="contact_details_logo email_icon"
                  loading="lazy"
                />
                <p>
                  <a href="mailto:info@mcmcladding.com">info@mcmcladding.com</a>
                </p>
              </li>
            </ul>
            <div className="social_icons_wrapper">
              <a
                href={fbpath}
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={facebook} alt="" className="fb_img" loading="lazy" />
              </a>
              <a
                href={linkedinpath}
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={linkedin} alt="" loading="lazy" />
              </a>
              <a
                href={instapath}
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={insta} alt="" loading="lazy" />
              </a>
              <a href={() => false} className="youtube social_icons_circle">
                <img
                  src={youtube}
                  alt="MCM youtube"
                  loading="lazy"
                  className="youtube_logo"
                />
              </a>
              <a
                href={twitterpath}
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={twitterX} alt="" className="twitter_img" loading="lazy" />
              </a>
              <a
                href={pinterestpath}
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={pinterest} alt="" className="pinterest_img" loading="lazy" />
              </a>
              
              {/* <a
                href={() => false}
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={twitter} alt="" 
                loading="lazy"/>
              </a> */}
            </div>
          </div>
          <div className="contact_details_flex_right">
            <h3>GET IN TOUCH</h3>
            <p>Feel free to drop us a line below!</p>
            <form className="contact_form" onSubmit={handleSubmit}>
              <div className="input_field_wrapper">
                <input
                  type="text"
                  className={`input_field ${values.name != "" ? "active" : ""}`}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                <label className="floating_label">name*</label>
                <div className="error">{formError.name}</div>
              </div>
              <div className="input_field_wrapper">
                <input
                  type="text"
                  className={`input_field ${
                    values.email != "" ? "active" : ""
                  }`}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <label className="floating_label">email*</label>
                <div className="error">{formError.email}</div>
              </div>
              <div className="input_field_wrapper country_code_field">
                <div className="country_code">
                  <select
                    className="countrycode_select"
                    name="country_code"
                    onfocus="this.size=10;"
                    value={countryCode}
                    onChange={(e) => setcountryCode(e.target.value)}

                    // onChange={handleChange}
                  >
                    {codes.map((code, i) => {
                      return (
                        <option value={`+${code}`} key={i}>
                          {`+${code}`}
                        </option>
                      );
                    })}
                  </select>
                  {/* <img
                    className="dropwdown_icon"
                    width={16}
                    height={8}
                    loading="lazy"
                    alt="dropdown icon"
                    src={rightBlackArrow}
                  /> */}
                  {/* <p className="error_message"></p> */}
                </div>
                <div className="number_field">
                  <input
                    type="tel"
                    className={`input_field ${
                      values.phone != "" ? "active" : ""
                    }`}
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <label className="floating_label">phone number*</label>
                </div>
                <div className="error">{formError.phone}</div>
              </div>
              <div className="input_field_wrapper input_field_wrapper_flex">
                <div className="input_select_field_left">
                  <select
                    className={`contact_dropdown input_field ${
                      values.type != "" ? "active" : "active"
                    }`}
                    onfocus="this.size=10;"
                    name="type"
                    value={values.type}
                    onChange={(e) => {
                      setProducts(
                        _.filter(productsData, { category: e.target.value })
                      );
                      handleChange(e);
                    }}
                  >
                    {types.map((item, i) => {
                      return (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <label className="floating_label">product type*</label>
                  <div className="error">{formError.type}</div>
                </div>
                <div className="input_select_field_right">
                  <select
                    className={`contact_dropdown input_field ${
                      values.type != "" ? "active" : "active"
                    }`}
                    name="product"
                    value={values.product}
                    onChange={handleChange}
                  >
                    {productsList}
                  </select>
                  <label className="floating_label">product*</label>
                  <div className="error">{formError.product}</div>
                </div>
              </div>
              <div className="input_field_wrapper input_field_wrapper_flex">
                <div className="input_select_field_left">
                  <select
                    className={`contact_dropdown input_field active`}
                    onfocus="this.size=10;"
                    name="state"
                    value={values.state}
                    onChange={(e) => {
                      console.log();
                      handleChange(e);
                      fetchCities(e.target[e.target.selectedIndex].getAttribute("data-id"));
                    }}
                  >
                    <option value="" >
                        Select State
                        </option>
                    {states.map((item, i) => {
                      return (
                        <option value={item.name} key={i} data-id={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label className="floating_label">State*</label>
                  <div className="error">{formError.state}</div>
                </div>
                <div className="input_select_field_right">
                  <select
                    className={`contact_dropdown input_field active`}
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  >
                    <option value="" >
                        Select City
                        </option>
                    {cities.map((item, i) => {
                      return (
                        <option value={item.name} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label className="floating_label">City*</label>
                  <div className="error">{formError.city}</div>
                </div>
              </div>
              <div className="input_field_wrapper">
                <input
                  type="text"
                  className={`input_field ${
                    values.message != "" ? "active" : ""
                  }`}
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                />
                <label className="floating_label">Message*</label>
                <div className="error">{formError.message}</div>
              </div>
              <div className="mcm_cta">
                <button type="submit">
                  <span className="mcm_cta">
                    {loading ? "Loading.." : "Submit"}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="contactsec3">
        <iframe
          className="map_frame"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.4291568631943!2d72.83505051482287!3d19.132682887055438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7549143f1d5%3A0x407b0a93e8fe6977!2sMCMFlexi%20Cladding%20India%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1669635333669!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>
    </>
  );
};

export default ContactUs;
