import React, { useEffect } from "react";
import "./TermsAndCondition.scss";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="terms_sec1"></section>
      <section className="terms_sec2">
        <div className="my_container">
          {/* <h1 className="main_heading">
            Welcome to https://www.mcmcladding.com/
          </h1> */}

          <div className="para_wrapper">
            <p className="description">
              Welcome to&nbsp;
              <Link className="link" to="/">
                https://www.mcmcladding.com/
              </Link>
            </p>
            <p className="description">
              These terms and conditions outline the rules and regulations for
              using MCM CLADDING INDIA PVT. LTD., located at&nbsp;
              <Link className="link" to="/">
                https://www.mcmcladding.com/
              </Link>
              &nbsp; By accessing this website, we assume you accept these terms
              and conditions. Do not continue to use&nbsp;
              <Link className="link" to="/">
                https://www.mcmcladding.com/
              </Link>
              &nbsp; if you do not agree to take all of the terms and conditions
              stated on this page.
            </p>
            <p className="description">
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company’s terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client’s needs in respect of
              provision of the Company’s stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to the same.
            </p>

            <div className="para_wrapper">
              <h6 className="title">Cookies</h6>
              <p className="description">
                We employ the use of cookies. By accessing&nbsp;
                <Link className="link" to="/">
                  https://www.mcmcladding.com/
                </Link>
                , you agreed to use cookies in agreement with the MCMFLEXI
                CLADDING INDIA PVT. LTD. Privacy Policy.
              </p>
              <p className="description">
                Most interactive websites use cookies to let us retrieve the
                user’s details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">License</h6>
              <p className="description">
                Unless otherwise stated, MCMFLEXI CLADDING INDIA PVT. LTD.
                and/or its licensors own the intellectual property rights for
                all material on&nbsp;
                <Link className="link" to="/">
                  https://www.mcmcladding.com/
                </Link>
                ,. All intellectual property rights are reserved. You may access
                this from&nbsp;
                <Link className="link" to="/">
                  https://www.mcmcladding.com/
                </Link>
                , for your own personal use subject to restrictions set in these
                terms and conditions.
              </p>
              <p className="description">You must not:</p>
              <ul className="list_wrapper">
                <li>
                  Republish material from&nbsp;
                  <Link className="link" to="/">
                    https://www.mcmcladding.com/
                  </Link>
                  ,&nbsp;
                </li>
                <li>
                  Sell, rent or sub-license material from&nbsp;
                  <Link className="link" to="/">
                    https://www.mcmcladding.com/
                  </Link>
                  ,
                </li>
                <li>
                  Sell, rent or sub-license material from&nbsp;
                  <Link className="link" to="/">
                    https://www.mcmcladding.com/
                  </Link>
                  ,
                </li>
                <li>
                  Redistribute content from&nbsp;
                  <Link className="link" to="/">
                    https://www.mcmcladding.com/
                  </Link>
                  ,{" "}
                </li>
              </ul>
              <p className="description">
                This Agreement shall begin on the date hereof. Our Terms and
                Conditions were created with the help of the Terms Feed Free
                Terms and Conditions Generator.
              </p>
              <p className="description">
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. MCMFLEXI CLADDING INDIA PVT. LTD. does not filter,
                edit, publish or review Comments prior to their presence on the
                website. Comments do not reflect the views and opinions of
              </p>
              <p className="description">
                MCMFLEXI CLADDING INDIA PVT. LTD., its agents and/or affiliates.
                Comments reflect the views and opinions of the person who posts
                their views and opinions. To the extent permitted by applicable
                laws,
              </p>
              <p className="description">
                MCMFLEXI CLADDING INDIA PVT. LTD. shall not be liable for the
                Comments or for any liability, damages or expenses caused and/or
                suffered as a result of any use of and/or posting of and/or
                appearance of the
                <br />
                Comments on this website.
              </p>
              <p className="description">
                MCMFLEXI CLADDING INDIA PVT. LTD . reserves the right to monitor
                all Comments and to remove any Comments which can be considered
                inappropriate, offensive or causes breach of these Terms and
                Conditions.
              </p>
              <p className="description">You warrant and represent that:</p>
              <ul className="list_wrapper">
                <li>
                  You are entitled to post the Comments on our website and have
                  all necessary licenses and consents to do so;
                </li>
                <li>
                  The Comments do not invade any intellectual property right,
                  including without limitation copyright, patent or trademark of
                  any third party;
                </li>
                <li>
                  The Comments do not contain any defamatory, libelous,
                  offensive, indecent or otherwise unlawful material which is an
                  invasion of privacy
                </li>
                <li>
                  The Comments will not be used to solicit or promote business
                  or custom or present commercial activities or unlawful
                  activity.
                </li>
              </ul>
              <p className="description">
                You hereby grant MCMFLEXI CLADDING INDIA PVT. LTD . a
                non-exclusive license to use, reproduce, edit and authorize
                others to use, reproduce and edit any of your Comments in any
                and all forms, formats or media.
              </p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">Hyperlinking to our Content</h6>
              <p className="description">
                The following organizations may link to our Website without
                prior written approval:
              </p>
              <ul className="list_wrapper">
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>
                  Online directory distributors may link to our Website in the
                  same manner as they hyperlink to the Websites of other listed
                  businesses; and
                </li>
                <li>
                  System wide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site.
                </li>
              </ul>
              <p className="description">
                We may consider and approve other link requests from the
                following types of organizations:
              </p>
              <ul className="list_wrapper">
                <li>
                  commonly-known consumer and/or business information sources;
                </li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
              </ul>
              <p className="description">
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of MCMFLEXI CLADDING INDIA PVT. LTD.; and (d) the link is in the
                context of general resource information.
              </p>
              <p className="description">
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; These organizations may
                link to our home page so long as the link: (a) is not in any way
                deceptive; These organizations may link to our home page so long
                as the link: (a) is not in any way deceptive; (b) (b) does not
                falsely imply sponsorship, endorsement or approval of the
                linking party and its products or services; and (c) fits within
                the context of the linking party’s site.
              </p>
              <p className="description">
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to ourc website, you must inform
                us by sending an e-mail to MCMFLEXI CLADDING INDIA PVT. LTD .
                Please include your name, your organization name, contact
                information as well as the URL of your site, a list of any URLs
                from which you intend to link to our Website, and a list of the
                URLs on our site to which you would like to link. Wait 2-3 weeks
                for a response.
              </p>
              <p className="description">
                Approved organizations may hyperlink to our Website as follows:
              </p>
              <ul className="list_wrapper">
                <li>By use of our corporate name; or</li>
                <li>
                  By use of the uniform resource locator being linked to; or
                </li>
                <li>
                  By use of any other description of our Website being linked to
                  that makes sense within the context and format of content on
                  the linking party’s site.
                </li>
              </ul>
              <p className="description">
                No use of MCMFLEXI CLADDING INDIA PVT. LTD 's logo or other
                artwork will be allowed for linking absent a trademark license
                agreement.
              </p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">iFrames</h6>
              <p className="description">
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">Content Liability</h6>
              <p className="description">
                We shall not be held responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that are rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">Your Privacy</h6>
              <p className="description">Please read Privacy Policy</p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">Reservation of Rights</h6>
              <p className="description">
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amend these terms and conditions and it’s linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">Removal of links from our website</h6>
              <p className="description">
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>
              <p className="description">
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">Disclaimer</h6>
              <p className="description">
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>
              <ul className="list_wrapper">
                <li>
                  limit or exclude our or your liability for death or personal
                  injury;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
              <p className="description">
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </p>
              <p className="description">
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
              <p className="description"></p>
            </div>

            <div className="para_wrapper">
              <h6 className="title">
                If you see the below keywords, replace it with the following:
              </h6>
              <table className="data_table">
                <tr>
                  <th>
                    <p className="text">
                      COMPANY_NAME <span className="colon">:</span>
                    </p>
                  </th>
                  <td>
                    <p className="text">MCMFLEXI CLADDING INDIA PVT. LTD</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p className="text">
                      COMPANY_WEBSITE_NAME <span className="colon">:</span>
                    </p>
                  </th>
                  <td>
                    <p className="text">
                      <Link className="link" to="/">
                        WWW.MCMCLADDING.COM
                      </Link>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p className="text">
                      <Link className="link" to="/">
                        WEBSITE_LINK
                      </Link>
                      &nbsp;
                      <span className="colon">:</span>
                    </p>
                  </th>
                  <td>
                    <p className="text">
                      <Link className="link" to="/">
                        WWW.MCMCLADDING.COM
                      </Link>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
