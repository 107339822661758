import React, { useState, useEffect } from "react";
import "./style.scss";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import {
  navmcmwhite,
  navmcmblack,
  navittimi,
  navcallblack,
  navcallwhite,
  navdownlaodblack,
  navdownloadwhite,
  navwpblack,
  navwpwhite,
  navimg1,
  navimg2,
  facebook,
  linkedin,
  insta,
  brochurePDF,
  youtube,
  twitterX,
  pinterest,
} from "../../images";

import { Link, NavLink } from "react-router-dom";
import { headercall, whatsappno } from "../../helpers/paths";
import { findAll as getAllCategories } from "../../Data/productCategories";
import Loader from "../Loader/Loader";

const body = document.querySelector("body");
const Header = ({ productIndex }) => {
  const { width } = useWindowDimensions();
  const [isLoader, setIsLoader] = useState(true);
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false);
  const [isBg, setIsbg] = useState(false);
  const [productExpand, setProductExpand] = useState(false);
  const [productHeadIndex, setProductHeadIndex] = useState(0);

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (width <= 834) {
      setIsbg(true);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const categoryDataList = getAllCategories().map((item, i) => (
    <li
      key={i}
      className={`sub_nav_link_item_wrapper ${
        i === productHeadIndex ? "active" : ""
      }`}
      onClick={() => setProductExpand(!productExpand)}
    >
      <NavLink
        to={{
          pathname: "/products",
          state: { slug: item.slug },
        }}
        activeClassName={` ${i === productHeadIndex ? "link_selected" : ""}`}
        className="link_item"
        onClick={() => {
          setProductHeadIndex(i);
          handleHamClick();
        }}
      >
        {item.title}
      </NavLink>
    </li>
  ));

  useEffect(() => {
    setIsHamburgerClicked(false);
    body.style.overflow = "auto";
    if (width <= 834) {
      setIsbg(true);
    }
  }, [window.location.pathname]);

  const handleScroll = () => {
    // if (width > 834) {
    if (window.pageYOffset > 60) {
      setIsbg(true);
    } else {
      setIsbg(false);
    }
    // } else {
    //   setIsbg(true);
    // }
  };
  const handleHamClick = () => {
    setIsHamburgerClicked(!isHamburgerClicked);

    if (!isHamburgerClicked) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
    if (width <= 834) {
      setIsbg(!isBg);
    }
  };

  return (
    <>
      <div
        style={{
          display: width > 992 && isLoader ? "block" : "none",
        }}
      >
        <Loader />
      </div>
      <section className={`navbar_wrapper ${isBg ? "navbar_bg" : null}`}>
        <div className="navbar_flex ">
          <div className="social_and_brochure_wrapper">
            <a href={headercall}>
              <img
                src={isBg ? navcallblack : navcallwhite}
                alt="MCM icon"
                className="nav_icons"
                loading="lazy"
              />
            </a>
            <a
              className={`brochure_text_wrapper ${
                isBg ? "brochure_text_wrapper_black" : null
              }`}
              href={brochurePDF}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={isBg ? navdownlaodblack : navdownloadwhite}
                alt="MCM icon"
                className="nav_icons"
                loading="lazy"
              />
              <span
                className={`brochure_text ${
                  isBg ? "brochure_text_black" : null
                }`}
              >
                brochure
              </span>
            </a>
            <a href={whatsappno} target="_blank" rel="noreferrer">
              <img
                src={isBg ? navwpblack : navwpwhite}
                alt="MCM icon"
                loading="lazy"
                className="nav_icons"
              />
            </a>
          </div>
        </div>
      </section>
      <Link to="/" className="mcm_logo_wrapper">
        <img
          src={isBg ? navmcmblack : navmcmwhite}
          alt="MCM icon"
          className="navbar_mcm_logo"
          onLoad={(navmcmblack, navmcmwhite ? imageLoaded : null)}
        />
      </Link>
      <div
        className={`hamburger_menu  ${isHamburgerClicked ? "active" : null} ${
          isBg ? "hamburger_lines_black" : null
        }`}
        aria-label="Hamburger menu"
        onClick={handleHamClick}
      >
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
      </div>

      <div
        className={`project_wrapper ${isHamburgerClicked ? "active" : null}`}
      >
        <img
          src={navimg1}
          alt="MCM navbar"
          className="navimg1"
          loading="lazy"
        />
        {/* <span className="project_span">ongoing project</span> */}
      </div>

      <div className={`contact_flex ${isHamburgerClicked ? "active" : null}`}>
        <div className="contact_flex_left">
          <img
            src={navimg2}
            alt="MCM navbar"
            className="navimg2"
            loading="lazy"
          />
          <div className="contact_text_container">
            <h3 className="get_in_touch">get in touch</h3>
            <p className="contact_desc">Ideate. Innovate. Invent</p>
            <p className="mail_wrapper">
              <a href="mailto:info@mcmcladding.com">info@mcmcladding.com</a>
            </p>
            <p className="call_wrapper">
              <a href="tel:+917045905068">+91 7045905068</a>
              <br />
              <a href="tel:+91 8291900355">+91 8291900355</a>
              {/* <br />
              <a href="tel:+91 9821074177">+91 9821074177</a> */}
            </p>
            <div className="social_icons_wrapper">
              <a
                href="https://www.facebook.com/mcmflexicladding/"
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={facebook} alt="" className="fb_img" loading="lazy" />
              </a>
              <a
                href="https://www.linkedin.com/in/mcmcladdingindia/"
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={linkedin} alt="" loading="lazy" />
              </a>
              <a
                href="https://www.instagram.com/mcmcladdingindia/"
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img src={insta} alt="" loading="lazy" />
              </a>
              <a href={() => false} className="youtube social_icons_circle">
                <img
                  src={youtube}
                  alt="MCM youtube"
                  loading="lazy"
                  className="youtube_logo"
                />
              </a>
              <a
                href="https://x.com/MCMCLADING"
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img
                  src={twitterX}
                  alt="twitter"
                  className="twitter_img"
                  loading="lazy"
                />
              </a>
              <a
                href="https://in.pinterest.com/mcmcladdingindia/"
                target="_blank"
                rel="noreferrer"
                className="social_icons_circle"
              >
                <img
                  src={pinterest}
                  alt="pinterest"
                  className="pinterest_img"
                  loading="lazy"
                />
              </a>
              {/* <a href={() => false} target="_blank" className="social_icons_circle" rel="noreferrer">
                <img src={twitter} alt="" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="contact_flex_right">
          <img
            src={navittimi}
            alt="Ittimi logo"
            className="ittimi_img"
            loading="lazy"
          />
          <p>
            Epitomizing the magic of clay, Ittimi possesses a plethora of
            intricate and innovative textures exclusively for the interiors.
          </p>
        </div>
      </div>

      <nav
        className={`nav_link_wrapper ${isHamburgerClicked ? "active" : null}`}
      >
        <ul
          className={`nav_links_list ${
            productExpand ? "nav_links_expand" : null
          }`}
        >
          <li className="nav_link_item_wrapper">
            <NavLink
              to="/"
              activeClassName="link_selected"
              className="link_item"
              exact
            >
              home
            </NavLink>
          </li>
          <li className="nav_link_item_wrapper">
            <NavLink
              to="/about-us"
              activeClassName="link_selected"
              className="link_item"
              exact
            >
              about us
            </NavLink>
          </li>
          <li
            className={`nav_link_item_wrapper product_nav_link_item_wrapper ${
              productExpand ? "product_expand" : null
            }`}
          >
            <span
              className="link_item product_link_item"
              onClick={() => setProductExpand(!productExpand)}
            >
              products
            </span>
            <ul>{categoryDataList}</ul>
          </li>
          <li className="nav_link_item_wrapper">
            <NavLink
              to="/projects"
              activeClassName="link_selected"
              className="link_item"
              exact
            >
              projects
            </NavLink>
          </li>
          <li className="nav_link_item_wrapper">
            <NavLink
              to="/downloads"
              activeClassName="link_selected"
              className="link_item"
              exact
            >
              downloads
            </NavLink>
          </li>
          <li className="nav_link_item_wrapper">
            <NavLink
              to="/events"
              activeClassName="link_selected"
              className="link_item"
              exact
            >
              events
            </NavLink>
          </li>
          {/* <li className="nav_link_item_wrapper">
            <NavLink
              to="/blog"
              activeClassName="link_selected"
              className="link_item"
              exact
            >
              blogs
            </NavLink>
          </li> */}
          <li className="nav_link_item_wrapper">
            <NavLink
              to="/contact-us"
              activeClassName="link_selected"
              className="link_item"
              exact
            >
              contact us
            </NavLink>
          </li>
        </ul>

        {width <= 834 ? (
          <div className="mb_contact_details">
            <p>
              <a href="mailto:info@mcmcladding.com">info@mcmcladding.com</a>
            </p>
            <p>
              {/* <a href="tel:2226735084">
                +91 22 2673 5084 &nbsp;&nbsp; | &nbsp;&nbsp;
              </a> */}
              <a href="tel:+917045905068">+91 7045905068 </a>
              <br />
              <a href="tel:+918291900355">+91 8291900355 </a>
              {/* <br />
              <a href="tel:+91 9821074177">+91 9821074177</a> */}
            </p>
          </div>
        ) : null}
      </nav>
      {width <= 834 ? (
        <div className="mb_social_and_brochure_wrapper">
          <a href="tel:+917045905068">
            <img
              src={navcallwhite}
              alt="MCM icon"
              className="nav_icons"
              loading="lazy"
            />
          </a>
          <a
            className="brochure_text_wrapper"
            href={brochurePDF}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={navdownloadwhite}
              alt="MCM icon"
              className="nav_icons"
              loading="lazy"
            />
            <span className="brochure_text">brochure</span>
          </a>
          <a
            href="https://wa.me/+917045905068"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={navwpwhite}
              alt="MCM icon"
              className="nav_icons"
              loading="lazy"
            />
          </a>
        </div>
      ) : null}
    </>
  );
};

export default Header;
